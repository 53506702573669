import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () =>
            import('@app/pages/login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'resetPassword',
        loadChildren: () =>
            import('@app/pages/reset/reset.module').then((m) => m.ResetModule),
    },
    {
        path: 'dashboard/content',
        loadChildren: () =>
            import('@app/pages/dashboard/dashboard.module').then(
                (m) => m.DashboardModule
            ),
    },
    {
        path: 'microsite',
        loadChildren: () =>
            import('@app/pages/microsite/microsite.module').then(
                (m) => m.MicrositeModule
            ),
    },
    {
        path: 'dashboard',
        redirectTo: 'dashboard/content',
    },
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
