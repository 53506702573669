import { DialogMessageComponent } from '@app/components/dialog-message/dialog-message.component';
import { selectDataBus, selectUI, selectUser } from '@app/store/selectors';
import { setUserLastVisitedPage } from '@app/store/actions';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, merge, of, fromEvent } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { DateTimeService } from './date-time.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { menuConfig } from '@app/config/menuConfig';
import { HttpClient } from '@angular/common/http';
import { Menu } from '@app/models/menu.model';
import {
    LanguageTabsPropsModel,
    ColumnLayout,
} from '@app/models/services/utilityModels';
import {
    TemplateContainersQrCode,
    PDFSecurityReportConfig,
    FlightInventoryQrCode,
    TemplateContainers,
    FlightInventory,
    PDFContentModel,
    PDFConfig,
} from '@app/models/services/mappingModels';
import { Injectable } from '@angular/core';
import { mapTo } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    countryCodes = require('../../assets/resources/countries_codes.json');
    weekdaysArray = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    googleIconsNames: Array<string> = [];
    title = 'internet-connection-check';
    mapElementIds: Array<string> = [];
    isGetAllUsersApiCalled = false;
    $online: Observable<boolean>;
    formattedCountryList = [];
    dataBusSubscription: any;
    currentSelectedNode = '';
    isLoadingContent = false;
    updateFilterValue = true;
    lastVisitedPage: string;
    isLoadingUsers = false;
    userSubscription: any;
    databusContent: any;
    uiSubscription: any;
    currentBgImage: any;
    isConnected = true;
    poiCategories = [];
    status = 'ONLINE'; //initializing as online by default
    menuItems: Menu[];
    currentPath = '';
    userRole = '';
    poiTags = [];
    email = '';
    subtitleAppFlavor = '';
    topicSubscribers;
    static hideDragDropIcon = false;
    static airports = [];

    validationPatterns = {
        emailAddress:
            /^(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$/,
        password: /^(?=.*[A-Z])(?=.*[!@#$%^&*)(+=._-])(?=.*[a-z]).{6}/,
        location:
            /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/,
        phoneNumber: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
        rating: /^([0-4])$|^([0-4]\.[0-9]{1,})$|^5$|^5.0{1,}$/,
        url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/,
        miaPoiID:
            /[\!\?\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\,\/\\]/g,
        equipmentKey: /^[A-Z0-9_-]*$/,
    };
    versionSubtitleAppFlavor: string;

    constructor(
        public dateTimeService: DateTimeService,
        private translate: TranslateService,
        private modalService: NgbModal,
        private http: HttpClient,
        private store: Store<any>,
        private router: Router
    ) {
        this.$online = merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(mapTo(true)),
            fromEvent(window, 'offline').pipe(mapTo(false))
        );

        const userState$ = this.store.select(selectUser);
        this.userSubscription = userState$.subscribe((userItem) => {
            this.userRole = userItem.userRole;
            this.email = userItem.email;
            this.lastVisitedPage = userItem.lastVisitedPage || 'welcome';
        });

        const uiState$ = this.store.select(selectUI);
        this.uiSubscription = uiState$.subscribe((uiItem) => {
            this.isLoadingUsers =
                this.userRole !== 'viewer' && this.userRole !== 'power_user'
                    ? uiItem.isLoadingUsers
                    : false;
            this.currentBgImage = uiItem.images.loginImage;
        });

        const dataBusState$ = this.store.select(selectDataBus);
        this.dataBusSubscription = dataBusState$.subscribe((dataBusItem) => {
            this.databusContent = dataBusItem.content;
            this.poiTags = dataBusItem.content.tags;
            this.poiCategories = dataBusItem.content.poiCategories;
            this.mapElementIds = dataBusItem.content.mapElementIDs;
            UtilityService.airports = dataBusItem.content.airports;
        });

        this.menuItems = menuConfig;

        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                let routerSplit = router.routerState.snapshot.url
                    .split('content/')[1]
                    ?.split('/');
                if (routerSplit) {
                    this.currentPath = routerSplit[0];
                } else {
                    routerSplit = router.routerState.snapshot.url.split('/');
                    this.currentPath = routerSplit[routerSplit.length - 1];
                }

                if (router.routerState.snapshot.url.includes('/dashboard/')) {
                    setTimeout(() => {
                        this.store.dispatch(
                            setUserLastVisitedPage({
                                lastVisitedPage: this.currentPath,
                            })
                        );
                    }, 100);
                }
            }
        });
        this.setFormattedCountryList();

        if (
            environment.appFlavor === 'universal' ||
            environment.appFlavor === 'mvp' ||
            environment.appFlavor === 'afw' ||
            environment.appFlavor === 'bwa'
        ) {
            this.initGoogleIconsNames();
        }
    }

    static getLanguagesTabsProps(
        extraProps?: {
            [key: string]: {
                [key: string]: any;
            };
        },
        getTabByKey = ''
    ): Array<LanguageTabsPropsModel> {
        let tabsArray = _.cloneDeep(environment.formLanguages);

        if (getTabByKey) {
            tabsArray = [tabsArray.find((tab) => tab.key === getTabByKey)];
        }

        tabsArray.map((element) => {
            element['id'] = element.key;

            if (extraProps && extraProps[element.key]) {
                Object.entries(extraProps[element.key]).forEach(
                    ([key, value]) => {
                        element[key] = value;
                    }
                );
            }

            return element;
        });

        return tabsArray;
    }

    checkCurrentConnection(): boolean {
        if (!navigator.onLine) {
            return false;
        } else {
            return true;
        }
    }

    isValidFormat(formatType: string, charStr: string): boolean {
        return this.validationPatterns[formatType]
            ? this.validationPatterns[formatType].test(charStr)
            : false;
    }

    generateRandomNumber(): number {
        const crypto = window.crypto;
        var array = new Uint32Array(1); // Compliant for security-sensitive use cases
        var value = Number(String(crypto.getRandomValues(array)[0]).charAt(0));
        if (value > 5 || value === this.currentBgImage) {
            return this.generateRandomNumber();
        }

        return value;
    }

    getPageTitle(path: string): string {
        let appFlavor = '';
        let siteName = '';

        //TODO For Hassan to review and deal with it
        switch (environment.appFlavor) {
            case 'lax':
                appFlavor = 'LAX';
                if (environment.production) {
                    siteName = this.translate.instant('MAIN.SITE_NAME_PROD');
                } else {
                    siteName = siteName =
                        this.translate.instant('MAIN.SITE_NAME_QA');
                }

                this.versionSubtitleAppFlavor = 'MAIN.PAXASSIST_CMS_LITE_V';
                this.subtitleAppFlavor = 'MAIN.PAXASSIST_CMS';
                break;
            case 'lga':
                appFlavor = 'LGA';
                if (environment.production) {
                    siteName = this.translate.instant('MAIN.SITE_NAME_PROD');
                } else {
                    siteName = siteName =
                        this.translate.instant('MAIN.SITE_NAME_QA');
                }

                this.versionSubtitleAppFlavor = 'MAIN.PAXASSIST_CMS_LITE_V';
                this.subtitleAppFlavor = 'MAIN.PAXASSIST_CMS';
                break;

            case 'mea':
                appFlavor = 'MEA';
                if (environment.production) {
                    this.translate.instant('MAIN.MEA_SITE_NAME_PROD');
                } else {
                    siteName = this.translate.instant('MAIN.MEA_SITE_NAME_QA');
                }

                this.versionSubtitleAppFlavor = 'MAIN.MEA_CREW_TABLET_PORTAL_V';
                this.subtitleAppFlavor = 'MAIN.MEA_CREW_TABLET_PORTAL';
                break;

            case 'neom':
                appFlavor = 'Neom';
                if (environment.production) {
                    siteName = this.translate.instant('MAIN.SITE_NAME_PROD');
                } else {
                    siteName = siteName =
                        this.translate.instant('MAIN.SITE_NAME_QA');
                }

                this.versionSubtitleAppFlavor = 'MAIN.PAXASSIST_CMS_LITE_V';
                this.subtitleAppFlavor = 'MAIN.PAXASSIST_CMS';
                break;

            case 'video-demo':
            case 'elpaso': {
                appFlavor = '';
                if (environment.production && environment.appEnv === 'prod') {
                    siteName = this.translate.instant('MAIN.SITE_NAME_PROD');
                    this.versionSubtitleAppFlavor = 'MAIN.PAXASSIST_CMS_LITE_V';
                    this.subtitleAppFlavor = 'MAIN.PAXASSIST_CMS';
                } else if (environment.appEnv === 'qa') {
                    siteName = this.translate.instant('MAIN.SITE_NAME_QA');
                    this.versionSubtitleAppFlavor = 'MAIN.PAXASSIST_CMS_LITE_V';
                    this.subtitleAppFlavor = 'MAIN.PAXASSIST_CMS';
                } else {
                    siteName = this.translate.instant('MAIN.SITE_NAME_DEV');
                    this.versionSubtitleAppFlavor = 'MAIN.PAXASSIST_CMS_LITE_V';
                    this.subtitleAppFlavor = 'MAIN.PAXASSIST_CMS';
                }
                break;
            }

            case 'mia':
                appFlavor = '';
                if (environment.appEnv === 'prod') {
                    siteName = this.translate.instant('MAIN.MIA_CMS');
                    this.versionSubtitleAppFlavor = 'MAIN.MIA_CMS_V';
                    this.subtitleAppFlavor = 'MAIN.MIA_CMS';
                } else if (environment.appEnv === 'qa') {
                    siteName = this.translate.instant('MAIN.MIA_QA_CMS');
                    this.versionSubtitleAppFlavor = 'MAIN.MIA_QA_CMS_V';
                    this.subtitleAppFlavor = 'MAIN.MIA_QA_CMS';
                } else if (environment.appEnv === 'dev') {
                    siteName = this.translate.instant('MAIN.MIA_DEV_CMS');
                    this.versionSubtitleAppFlavor = 'MAIN.MIA_DEV_CMS_V';
                    this.subtitleAppFlavor = 'MAIN.MIA_DEV_CMS';
                }

                break;

            case 'bwa':
                appFlavor = '';
                if (environment.appEnv === 'prod') {
                    siteName = this.translate.instant(
                        'MAIN.AIRLINE_APP_BWA_CMS'
                    );
                    this.versionSubtitleAppFlavor =
                        'MAIN.AIRLINE_APP_BWA_CMS_V';
                    this.subtitleAppFlavor = 'MAIN.AIRLINE_APP_BWA_CMS';
                } else if (environment.appEnv === 'qa') {
                    siteName = this.translate.instant(
                        'MAIN.AIRLINE_APP_BWA_QA_CMS'
                    );
                    this.versionSubtitleAppFlavor =
                        'MAIN.AIRLINE_APP_BWA_QA_CMS_V';
                    this.subtitleAppFlavor = 'MAIN.AIRLINE_APP_BWA_QA_CMS';
                } else if (environment.appEnv === 'dev') {
                    siteName = this.translate.instant(
                        'MAIN.AIRLINE_APP_BWA_DEV_CMS'
                    );
                    this.versionSubtitleAppFlavor =
                        'MAIN.AIRLINE_APP_BWA_DEV_CMS_V';
                    this.subtitleAppFlavor = 'MAIN.AIRLINE_APP_BWA_DEV_CMS';
                }

                break;
            case 'mvp':
                appFlavor = '';
                if (environment.appEnv === 'dev') {
                    siteName = this.translate.instant(
                        'MAIN.AIRLINE_APP_MVP_DEV_CMS'
                    );
                    this.versionSubtitleAppFlavor =
                        'MAIN.AIRLINE_APP_MVP_DEV_CMS_V';
                    this.subtitleAppFlavor = 'MAIN.AIRLINE_APP_MVP_DEV_CMS';
                }

                break;

            case 'afw':
                appFlavor = '';
                if (environment.production) {
                    siteName = this.translate.instant(
                        'MAIN.AIRLINE_APP_AFW_CMS'
                    );
                    this.versionSubtitleAppFlavor =
                        'MAIN.AIRLINE_APP_AFW_CMS_V';
                    this.subtitleAppFlavor = 'MAIN.AIRLINE_APP_AFW_CMS';
                } else {
                    siteName = this.translate.instant(
                        'MAIN.AIRLINE_APP_AFW_QA_CMS'
                    );
                    this.versionSubtitleAppFlavor =
                        'MAIN.AIRLINE_APP_AFW_QA_CMS_V';
                    this.subtitleAppFlavor = 'MAIN.AIRLINE_APP_AFW_QA_CMS';
                }

                break;
            case 'universal':
                appFlavor = '';
                if (environment.production) {
                    siteName = this.translate.instant(
                        'MAIN.AIRLINE_APP_UNIVERSAL_CMS'
                    );
                    this.versionSubtitleAppFlavor =
                        'MAIN.AIRLINE_APP_UNIVERSAL_CMS_V';
                    this.subtitleAppFlavor = 'MAIN.AIRLINE_APP_UNIVERSAL_CMS';
                } else {
                    siteName = this.translate.instant(
                        'MAIN.AIRLINE_APP_UNIVERSAL_QA_CMS'
                    );
                    this.versionSubtitleAppFlavor =
                        'MAIN.AIRLINE_APP_UNIVERSAL_QA_CMS_V';
                    this.subtitleAppFlavor =
                        'MAIN.AIRLINE_APP_UNIVERSAL_QA_CMS';
                }

                break;
            default:
                siteName = '';
                break;
        }

        if (
            !this.hasTranslationKey(
                `PAGE_TITLE.${path ? path.toUpperCase() : ''}`
            )
        ) {
            return `${siteName}`;
        }

        return `${this.translate.instant(
            `PAGE_TITLE.${path ? path.toUpperCase() : ''}`
        )} - ${appFlavor} ${siteName}`;
    }

    openDialog(header: string, body: string, viewButtons: Array<string>): any {
        return new Promise((resolve, reject) => {
            try {
                const modalRef = this.modalService.open(DialogMessageComponent);
                modalRef.componentInstance.dialogMessage = {
                    header: this.hasTranslationKey(header)
                        ? this.translate.instant(header)
                        : header,
                    body: this.hasTranslationKey(body)
                        ? this.translate.instant(body)
                        : body,
                    viewButtons,
                };
                modalRef.result
                    .then((dialogResult) => resolve(dialogResult))
                    .catch((error) => resolve(error));
            } catch (e) {
                reject(e);
                return null;
            }
        });
    }

    hasTranslationKey(key: string): boolean {
        const translation = key ? this.translate.instant(key) : '';
        return translation !== key && translation !== '';
    }

    sortArrayOfObjects(
        data: {}[] = [],
        searchField: string = '',
        subSearchField: string = ''
    ): {}[] {
        if (searchField) {
            return data.sort((a, b) => {
                let compareField1;
                let compareField2;

                if (subSearchField) {
                    compareField1 =
                        a[searchField][subSearchField].toUpperCase();
                    compareField2 =
                        b[searchField][subSearchField].toUpperCase();
                } else if (typeof a[searchField] !== 'object') {
                    //checks if searchField is not a sub-object or a sub-array
                    compareField1 = a[searchField].toUpperCase();
                    compareField2 = b[searchField].toUpperCase();
                }

                if (compareField1 < compareField2) {
                    return -1;
                }
                if (compareField1 > compareField2) {
                    return 1;
                }
                // names must be equal
                return 0;
            });
        }

        return data;
    }

    convertTime(departureTime: string): string {
        const splitDepartureTime: Array<string> = departureTime.split(':');

        const currentHour: number = splitDepartureTime[0]
            ? parseFloat(splitDepartureTime[0])
            : 0;

        const currentMinute: number = splitDepartureTime[1]
            ? parseFloat(splitDepartureTime[1]) / 60
            : 0;

        const time: number = currentHour + currentMinute;

        if (time >= 5.5 && time < 11.5) {
            return 'morning';
        }
        if (time >= 11.5 && time < 17.5) {
            return 'afternoon';
        } else if (time >= 17.5 && time < 23.5) {
            return 'evening';
        } else if (time >= 23.5 || time < 5.5) {
            return 'night';
        }
    }

    async getPDFConfig(
        flightInventory: FlightInventory = new FlightInventory({})
    ): Promise<PDFConfig> {
        const content: Array<PDFContentModel | string> = [];

        for (let i = 0; i < flightInventory.containers.length; i++) {
            let itemHeader = null;
            let subHeader = null;
            let itemBody = null;

            itemHeader = await this.getLayout(flightInventory, i, 'header');

            if (itemHeader) {
                content.push(itemHeader);
            }

            // Remove subHeader to return to the original PDF layout implementation :SF
            subHeader = await this.getLayout(flightInventory, i, 'subHeader');

            if (subHeader) {
                content.push(subHeader);
            }

            itemBody = await this.getLayout(flightInventory, i, 'body');

            if (itemBody) {
                content.push(itemBody);
            }
        }

        return new PDFConfig({ content });
    }

    async getLayout(
        flightTemplateData: FlightInventory,
        index: number,
        type: string
    ): Promise<{}> {
        if (!flightTemplateData) {
            return {};
        }

        const containers: Array<TemplateContainers> = flightTemplateData
            .containers[index]
            ? [flightTemplateData.containers[index]]
            : [];

        if (type === 'header') {
            // return {
            //   text: `${flightTemplateData.flightNumber} | ${
            //     flightTemplateData.departureAirportCode
            //   }-${flightTemplateData.arrivalAirportCode} | POS ${
            //     flightTemplateData.containers[index]?.containerPosition
            //   } | ${this.dateTimeService.formatDate(flightTemplateData.flightDate, "MMM DD")}`,
            //   style: "headerItem",
            //   border: [false, false, false, false],
            // };
            const flightData = flightTemplateData.associatedFlight?.find(
                (item) =>
                    flightTemplateData.containers[index].availableForFlight ===
                    `${item.operatingAirline}${item.flightNumber}`
            );

            return {
                text: flightData
                    ? `${flightData.operatingAirline}${flightData.flightNumber} | ${flightData.departureAirportCode}-${flightData.arrivalAirportCode}`
                    : `${flightTemplateData.flightNumber} | ${flightTemplateData.departureAirportCode}-${flightTemplateData.arrivalAirportCode}`,
                style: 'headerItem',
                border: [false, false, false, false],
            };
        } else if (type === 'subHeader') {
            const flightData = flightTemplateData.associatedFlight?.find(
                (item) =>
                    flightTemplateData.containers[index].availableForFlight ===
                    `${item.operatingAirline}${item.flightNumber}`
            );

            return {
                text: `${flightTemplateData.templateName} | ${
                    flightData
                        ? this.dateTimeService.formatDate(
                              flightData.localDepartureDate,
                              'MMM DD'
                          )
                        : this.dateTimeService.formatDate(
                              flightTemplateData.flightDate,
                              'MMM DD'
                          )
                }`,
                style: 'headerItem',
                border: [false, false, false, false],
            };
        } else {
            const flightContainers = [];
            containers?.forEach((container) => {
                const contents = [];
                container?.contents?.forEach((content) => {
                    contents.push({ i: content.item, q: content.quantity });
                });
                flightContainers.push(
                    new TemplateContainersQrCode({
                        ...container,
                        contents,
                    })
                );
            });

            const dataImage = await this.getImage(
                `https://api.qrserver.com/v1/create-qr-code/?size=160x160&data=${JSON.stringify(
                    new FlightInventoryQrCode({
                        ...flightTemplateData,
                        containers: flightContainers,
                    })
                )}`
            );

            return {
                style: 'tableContainer',
                layout: 'noBorders',
                table: {
                    widths: [
                        {
                            width: 120,
                        },
                        {
                            width: '*',
                        },
                    ],
                    heights: [162], //200
                    body: [
                        [
                            {
                                image: dataImage,
                                width: 120,
                                height: 120,
                                style: 'qrCode',
                                border: [false, false, false, false],
                            },
                            {
                                text: this.getSecondColumnLayout(
                                    flightTemplateData.containers,
                                    index
                                ),
                                width: '100%',
                                style: 'itemList',
                                border: [false, false, false, false],
                            },
                        ],
                    ],
                },
            };
        }
    }

    getSecondColumnLayout(
        containers: TemplateContainers[],
        index: number
    ): Array<ColumnLayout> {
        const formattedArray = [];
        const data = containers ? containers[index]?.contents : null;

        if (data) {
            data.map((element) => {
                formattedArray.push(
                    new ColumnLayout({
                        text: element.quantity,
                        style: 'code',
                    })
                );

                formattedArray.push(
                    new ColumnLayout({
                        text:
                            ` ${element.item} ${
                                containers[index].contentsType === 'MEAL'
                                    ? '/' +
                                      this.getMealCount(
                                          containers,
                                          element.item
                                      )
                                    : ''
                            }\n` || '',
                        style: 'itemCode',
                    })
                );
            });
        }

        return formattedArray;
    }

    getImage(imageURL: any): Promise<string> {
        return new Promise((resolve) => {
            this.urlToBase64(imageURL, (base64Encode) => {
                resolve(`data:image/png;base64,${base64Encode}`);
            });
        });
    }

    async urlToBase64(
        imageURL: string,
        callback: (base64Encode) => void
    ): Promise<any> {
        return await this.http
            .get(imageURL, {
                responseType: 'arraybuffer',
            })
            .subscribe(
                (res) => {
                    // transforms arrayBuffer to base64
                    const base64File = btoa(
                        [].reduce.call(
                            new Uint8Array(res),
                            function (p, c) {
                                return p + String.fromCharCode(c);
                            },
                            ''
                        )
                    );

                    callback(base64File);
                },
                () => {}
            );
    }

    getMealCount(containers: TemplateContainers[], meal: string): number {
        let value = 0;
        if (containers && containers.length > 0) {
            containers.forEach((container) => {
                container.contents?.forEach((content) => {
                    if (content.item === meal) {
                        value += content.quantity;
                    }
                });
            });
        }
        return value;
    }

    async getSecurityReportPDFConfig(
        flightDetails: any
    ): Promise<PDFSecurityReportConfig> {
        const logoURL = 'assets/flavorAssets/external-images/sita-logo.png';

        const contentBody = this.getSecurityReportList(flightDetails);

        const header = {
            style: 'header',
            layout: 'noBorders',
            table: {
                widths: ['*', 'auto'],
                layout: 'noBorders',
                body: [
                    [
                        {
                            image: await new Promise((resolve) => {
                                this.urlToBase64(logoURL, (base64Encode) => {
                                    resolve(
                                        `data:image/png;base64,${base64Encode}`
                                    );
                                });
                            }),
                            width: 150,
                        },

                        {
                            layout: 'noBorders',
                            table: {
                                body: [
                                    [
                                        {
                                            text: `${this.translate.instant(
                                                'SECURITY_REPORT.INVENTORY'
                                            )} #${flightDetails.regNumber}`,
                                            style: 'inventoryTitle',
                                        },
                                    ],
                                    [
                                        {
                                            layout: 'noBorders',
                                            table: {
                                                body: [
                                                    [
                                                        {
                                                            svg: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M21 16v-2l-8-5V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-8 5v2l8-2.5V19l-2 1.5V22l3.5-1 3.5 1v-1.5L13 19v-5.5l8 2.5z"/></svg>',
                                                            width: 12,
                                                        },
                                                        {
                                                            text: `${flightDetails.flightDate} | ${flightDetails.departureTime} | ${flightDetails.flightNumber} | ${flightDetails.departureAirportCode}`,
                                                            style: 'flightInfo',
                                                        },
                                                        {
                                                            svg: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z"/></svg>',
                                                            width: 12,
                                                        },
                                                        {
                                                            text: `${flightDetails.arrivalAirportCode}`,
                                                            style: 'flightInfo',
                                                        },
                                                    ],
                                                ],
                                            },
                                        },
                                    ],
                                ],
                            },
                        },
                    ],
                ],
            },
        };

        const contentFirstSection = {
            style: 'contentHeader',
            layout: {
                hLineWidth(i, node) {
                    return i === 0 || i === node.table.body.length ? 1 : 0;
                },
                vLineWidth() {
                    return 0;
                },
                hLineColor() {
                    return 'gray';
                },
                vLineColor() {
                    return 'gray';
                },
            },

            table: {
                widths: ['*', '*', '*'],
                heights: ['*'],
                body: [
                    [
                        {
                            layout: 'noBorders',
                            table: {
                                body: [
                                    [
                                        {
                                            text: this.translate.instant(
                                                'SECURITY_REPORT.IN_FLIGHT_DIVISION'
                                            ),
                                            style: 'contentHeaderText',
                                            margin: [0, 10, 0, 0],
                                        },
                                    ],
                                    [
                                        {
                                            text: this.translate.instant(
                                                'SECURITY_REPORT.CATERING_HANDLING_DEPT'
                                            ),
                                            style: 'contentHeaderSubtitle',
                                        },
                                    ],
                                    [
                                        {
                                            text: this.translate.instant(
                                                'SECURITY_REPORT.PREP_&_EQPT_SECTION'
                                            ),
                                            style: 'contentHeaderSubtitle',
                                            margin: [0, 0, 0, 10],
                                        },
                                    ],
                                ],
                            },
                        },
                        {
                            text: this.translate.instant(
                                'SECURITY_REPORT.SECURITY_SEAL'
                            ),
                            style: 'contentHeaderText',
                            alignment: 'center',
                            margin: [0, 28, 0, 0],
                        },
                        {
                            text: 'IFS-CH-001',
                            style: 'contentHeaderText',
                            alignment: 'right',
                            margin: [0, 28, 0, 0],
                        },
                    ],
                ],
            },
        };

        const contentSecondSection = {
            style: 'contentTable',
            layout: {
                hLineWidth(i: number) {
                    return i === 1 ? 1.5 : i === 0 ? 0 : 1;
                },
                vLineWidth() {
                    return 0;
                },
                hLineColor(i: number) {
                    return i === 1 ? 'black' : 'gray';
                },
                vLineColor() {
                    return 'gray';
                },
            },
            table: {
                headerRows: 1,
                widths: ['*', '*', '*', '*', '*'],
                heights: ['*'],
                body: contentBody,
            },
        };

        const footer = {
            style: 'footerContainer',
            layout: 'noBorders',
            table: {
                widths: ['*', 'auto'],
                layout: 'noBorders',
                body: [
                    [
                        {
                            layout: 'noBorders',
                            table: {
                                body: [
                                    [
                                        {
                                            text: this.translate.instant(
                                                'SECURITY_REPORT.CHECKED_AND_SEALED_BY_OFFICER'
                                            ),
                                            style: 'checkedTitle',
                                        },
                                    ],
                                    [
                                        {
                                            text: `${this.translate.instant(
                                                'SECURITY_REPORT.FULL_NAME'
                                            )}: ....................................`,
                                            style: 'footerInputs',
                                        },
                                    ],
                                    [
                                        {
                                            text: `${this.translate.instant(
                                                'SECURITY_REPORT.SIGNATURE'
                                            )}: ....................................`,
                                            style: 'footerInputs',
                                        },
                                    ],
                                ],
                            },
                        },
                        {
                            layout: 'noBorders',
                            table: {
                                body: [
                                    [
                                        {
                                            text: this.translate.instant(
                                                'SECURITY_REPORT.RECEIVED_BY_CABIN_CREW'
                                            ),
                                            style: 'checkedTitle',
                                        },
                                    ],
                                    [
                                        {
                                            text: `${this.translate.instant(
                                                'SECURITY_REPORT.FULL_NAME'
                                            )}: ....................................`,
                                            style: 'footerInputs',
                                        },
                                    ],
                                    [
                                        {
                                            text: `${this.translate.instant(
                                                'SECURITY_REPORT.SIGNATURE'
                                            )}: ....................................`,
                                            style: 'footerInputs',
                                        },
                                    ],
                                ],
                            },
                        },
                    ],
                ],
            },
        };

        return new PDFSecurityReportConfig({
            header,
            footer,
            content: [contentFirstSection, contentSecondSection],
        });
    }

    getSecurityReportList(flightDetails: any): any {
        const dataList = [
            [
                {
                    text: this.translate.instant(
                        'SECURITY_REPORT.CONTAINER_TYPE'
                    ),
                    style: 'contentTableHeader',
                },
                {
                    text: this.translate.instant(
                        'SECURITY_REPORT.CONTENT_TYPE'
                    ),
                    style: 'contentTableHeader',
                },
                {
                    text: this.translate.instant('SECURITY_REPORT.POSITION'),
                    style: 'contentTableHeader',
                },
                {
                    text: this.translate.instant('SECURITY_REPORT.CONTENT'),
                    style: 'contentTableHeader',
                },
                {
                    text: this.translate.instant('SECURITY_REPORT.SEALS'),
                    style: 'contentTableHeader',
                },
            ],
        ];
        if (flightDetails && flightDetails.containers) {
            flightDetails.containers.forEach((element) => {
                const flightRow = [];
                const contents = [];
                const seals = [];
                const containerType = element.containerType;
                const contentType = element.contentsType;
                const position = element.containerPosition;

                if (element.contents) {
                    element.contents.forEach((content) => {
                        contents.push(
                            `${content.item} | ${content.quantity}\n`
                        );
                    });
                }

                if (element.seal) {
                    element.seal.forEach((seal) => {
                        seals.push(`${seal}\n`);
                    });
                }

                flightRow.push({
                    text: containerType,
                    style: 'contentTableData',
                });
                flightRow.push({
                    text: contentType,
                    style: 'contentTableData',
                });
                flightRow.push({ text: position, style: 'contentTableData' });
                flightRow.push({ text: contents, style: 'contentTableData' });
                flightRow.push({ text: seals, style: 'contentTableData' });

                dataList.push(flightRow);
            });
        }

        return dataList;
    }

    compareData(
        firstArray: Array<string> | {}[] | {} = [],
        secondArray: Array<string> | {}[] | {} = []
    ): boolean {
        return _.isEqual(firstArray, secondArray);
    }
    setFormattedCountryList(): void {
        const formattedCodes = [];
        if (this.countryCodes) {
            this.countryCodes.forEach((element) => {
                formattedCodes.push({
                    label: element.ISO3166_1_Alpha_3,
                    key: element.ISO3166_1_Alpha_3,
                    text: `${element.Country_Name}`,
                    flagCode: element.ISO3166_1_Alpha_2.toLowerCase(),
                    dialNumber: element.Dial,
                    displayNumber: element.Display,
                    group: 'dropdown_group.all_countries',
                });
            });
        }
        this.sortArrayOfObjects(formattedCodes, 'text');
        this.formattedCountryList = formattedCodes.slice();
    }

    copyMessageToClipboard(message: string | number = ''): void {
        const listener = (e: ClipboardEvent) => {
            e.clipboardData.setData(
                'text/plain',
                message ? message.toString() : ''
            );
            e.preventDefault();
        };

        document.addEventListener('copy', listener);
        navigator.clipboard.writeText(message.toString());
        document.removeEventListener('copy', listener);
    }

    convertTimeTo12HourFormat(value: string): string {
        let time = value;

        const timeSplit = value.split(':');
        const hours = timeSplit[0];

        let isPM = true;
        if (+hours < 12) {
            isPM = false;
        } else if (+hours > 12) {
            time = (+hours - 12).toString();

            if (time.length < 2) {
                time = '0' + time;
            }

            time += ':' + timeSplit[1];
        }

        time += isPM ? ' PM' : ' AM';

        return time;
    }

    convertWeekDaysArrayToText(daysArray: Array<string>): string {
        let daysStr = '';

        let startIndex = this.weekdaysArray.indexOf(daysArray[0]);

        let isContinuous = true;
        if (daysArray.length > 1) {
            for (let i = 1; i < daysArray.length; i++) {
                // determine whether days are continuous or not
                const index = this.weekdaysArray.indexOf(daysArray[i]);
                if (index !== startIndex + 1) {
                    isContinuous = false;
                    break;
                }

                startIndex++;
            }
        } else {
            isContinuous = false;
        }

        if (isContinuous) {
            daysStr = daysArray[0] + ' – ' + daysArray[daysArray.length - 1];
        } else {
            // add comma between each day except for the last index
            daysArray.forEach((day, index) => {
                daysStr += day + (index === daysArray.length - 1 ? '' : ', ');
            });
        }

        return daysStr;
    }

    initGoogleIconsNames(): void {
        this.http
            .get('assets/resources/google-icons.json')
            .subscribe((res: any) => {
                if (res) {
                    const iconNames = [];

                    res.icons.forEach((element) => {
                        iconNames.push({
                            key: element.name,
                            label: element.name,
                        });
                    });

                    this.googleIconsNames = iconNames;
                }
            });
    }

    getBroadcastTopicLabel(key: string): string {
        const topic = this.databusContent.topics?.find(
            (element) => element.key === key
        );

        if (topic) {
            return topic.label || topic.name.en;
        }

        if (environment.appFlavor === 'mia') {
            return this.getFlightLabel(key);
        }

        if (
            !this.databusContent.flightNotifications?.find(
                (flight) => flight.key === key
            )
        ) {
            return key;
        }

        const info = key.split('_');

        const date = info[info.length - 1];

        const label = `${info[1].toUpperCase()} ${
            info[2]
        } ${this.dateTimeService.getMonthByName(
            date.charAt(4) + date.charAt(5)
        )} ${date.charAt(6) + date.charAt(7)}`;

        return label;
    }

    getFlightLabel(key: string): string {
        if (!key.includes('_')) {
            return key;
        }

        const info = key.split('_');

        const date = key.includes('_d_')
            ? key.split('_d_')[1]
            : key.split('_a_')[1];

        const label = `${info[1].toUpperCase()} ${info[2]} ${
            key.includes('_d_') ? 'Departing' : 'Arriving'
        } ${this.dateTimeService.getMonthByName(date?.replace(/_/g, '-'))} ${
            info[info.length - 1]
        }`;

        return label;
    }
}
