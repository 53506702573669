export const environment = {
    pageName: 'LAX CMS',
    appFlavor: 'lax',
    production: false,
    appEnv: 'qa',
    googleMapsKey: 'AIzaSyD1L7I8Icn-y1q0V6Qh_PMLMDVpS6BT6K4',
    apiConfig: {
        projectEndpoint: 'https://lax-api-qa.itxi.aero',
        apiKey: 'auJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9',
    },
    firebase: {
        apiKey: 'AIzaSyA2Grp3j1KO0XwpPsX9bEqHWkb8JrSdi24',
        authDomain: 'sita-pax-assist-qa.firebaseapp.com',
        databaseURL: 'https://sita-pax-assist-qa.firebaseio.com',
        projectId: 'sita-pax-assist-qa',
        storageBucket: 'sita-pax-assist-qa.appspot.com',
        messagingSenderId: '795758617869',
        appId: '1:795758617869:web:ae776d2258a4f426cafc44',
    },
    googleAnalyticsTrackId: 'UA-107236709-2',
    rolesWithLoginAccess: [
        { role: 'admin', route: 'dashboard/content/users', node: 'users' },
        {
            role: 'editor',
            route: 'dashboard/content/attractions',
            node: 'attractions',
        },
    ],
    sessionTokenCheckInterval: 300000,
    getAllFlightsListInterval: 0,
    sessionTokenExtension: true,
    defaultRoute: 'dashboard/content/attractions',
    enableRandomBackground: true,
    isGoogleAnalyticEnabled: true,
    airportCoordinates: {
        latitude: 33.945,
        longitude: -118.4,
        ISO3166_1_Alpha_2: 'US',
    },
    videoCategories: [
        { key: 'What’s New' },
        { key: 'Around LAX' },
        { key: 'Getting Around' },
        { key: 'LA Tourism' },
        { key: 'Social Responsibility' },
    ],
    userTableLanguages: [
        { key: 'enes', label: 'LANGUAGES.ALL_LANGUAGES' },
        { key: 'en', label: 'LANGUAGES.ENGLISH_ONLY' },
        { key: 'es', label: 'LANGUAGES.SPANISH_ONLY' },
    ],
    userTablesRole: [
        { key: 'agent', label: 'ROLES.AGENT' },
        { key: 'editor', label: 'ROLES.EDITOR' },
        { key: 'admin', label: 'ROLES.ADMIN' },
    ],

    formLanguages: [
        { key: 'en', title: 'LANGUAGES.ENGLISH' },
        { key: 'es', title: 'LANGUAGES.ESPANOL' },
    ],
    microsoftSSOClientId: '',
    googleSSOClientId: '',
    signInOptions: ['password'],
    appCategories: [],
    payment_method: [],
    payment_status: [],
    orientationArray: [],
    containerType: [],
    contentType: [],
    contentItems: [],
    equipmentType: [],
    departureTime: [],
    sectors: [],
    cartItems: [],
    tags: [],
};
