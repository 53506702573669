import { OfferTripTypeEnum } from '@app/models/enums/offerTripTypeEnum';
import { selectDataBus, selectUser } from '@app/store/selectors';
import { getStorage, ref, listAll } from 'firebase/storage';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { EventHubService } from './event-hub.service';
import { DateTimeService } from './date-time.service';
import { UtilityService } from './utility.service';
import { setRowData } from '@app/store/actions';
import { Injectable } from '@angular/core';
import {
    NormalizedIntroSlidesAirlineAppModel,
    NormalizedFareFieldsAirlineAppModel,
    NormalizedHotelsAttractionsELPModel,
    FeaturedCountriesAirlineAppModel,
    MappedIntroSlidesAirlineAppModel,
    MappedHotelsAttractionsELPModel,
    MappedFareFieldsAirlineAppModel,
    NormalizeAirportAirlineAppModel,
    NormalizeAirlineAppBannerModel,
    NormalizeOfferAirlineAppModel,
    NormalizePagesAirlineAppModel,
    OperatingHoursRecordsModel,
    PageActionsAirlineAppModel,
    NormalizedBroadcastsModel,
    NormalizeMiaPoiCategories,
    EquipmentAirlineAppModel,
    NormalizeVideosDataModel,
    MappedFlightInventories,
    OperatingHoursDataModel,
    NormalizeGeoFencesModel,
    NormalizedPoisELPModel,
    NormalizeCategoryModel,
    MappedCategoryDropDown,
    MappedMiaPoiCategories,
    AirportAirlineAppModel,
    MappedDistributionList,
    KioskNameLAXDataModel,
    NormalizedTopicsModel,
    BannerAirlineAppModel,
    CustomExportTemplate,
    MappedGeoFencesModel,
    PagesAirlineAppModel,
    OfferAirlineAppModel,
    FaresAirlineAppModel,
    AirlinesAPIDataModel,
    NormalizeMiaAirline,
    NormalizeMiaPoiTags,
    SubCategoryDropDown,
    MappedPoisELPModel,
    HotelAndAttraction,
    KioskNameDataModel,
    TemplateContainers,
    MappedTopicsModel,
    NormalizePagesELP,
    FlightInventories,
    MappedMiaPoiTags,
    DistributionList,
    MappedCategories,
    AssociatedFlight,
    PageActionsModel,
    NormalizeMiaPage,
    MappedMiaAirline,
    HomeBannerModel,
    NormalizeMiaPoi,
    NormalizeVideo,
    MappedCategory,
    MappedActivity,
    MoreLinksModel,
    MappedPagesELP,
    MappedMiaPage,
    UserDataModel,
    MappedRequest,
    MappedFlight,
    KioskNameLAX,
    MappedSurvey,
    ContentModel,
    MappedMiaPoi,
    ChatbotLogs,
    VideoModel,
    Categories,
    Concourses,
    MappedPage,
    MappedUser,
    Templates,
    KioskName,
    DataModel,
    Requests,
    Category,
    Activity,
    Section,
    Survey,
    Flight,
    Agents,
    Video,
} from '@app/models/services/mappingModels';
import { FormGroup } from '@angular/forms';
import {
    DoorCoordinateSectionModel,
    ConfigFormInputsModel,
    ConfigFormModel,
} from '@app/models/config-form.model';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class MappingService {
    tmpPagesArrays = [];
    apiRequestData: any;
    userToken: any;

    constructor(
        private dateTimeService: DateTimeService,
        private eventHubService: EventHubService,
        private utilityService: UtilityService,
        private translate: TranslateService,
        public store: Store<any>
    ) {
        const userState$ = store.select(selectUser);

        userState$.subscribe((userItem) => {
            this.userToken = userItem.userToken;
        });

        const dataBusState$ = store.select(selectDataBus);

        dataBusState$.subscribe((dataItem) => {
            this.apiRequestData = dataItem.apiRequestData;
        });
    }

    mapKioskNames(kioskNameData: KioskNameDataModel): Array<KioskName> {
        const mappedKioskNames: Array<KioskName> = [];

        if (environment.appFlavor === 'elpaso') {
            // @ts-ignore
            return kioskNameData;
        }

        Object.entries(kioskNameData).forEach(([key, value]) => {
            let obj = {};

            if (!value.computer_name) {
                value['computer_name'] = key;
            }

            obj = new KioskName(value);

            mappedKioskNames.push(obj);
        });
        return mappedKioskNames;
    }

    mapKioskNamesLAX(kioskNames: KioskNameLAXDataModel): Array<KioskNameLAX> {
        const kioskArray: Array<KioskNameLAX> = [];

        if (environment.appFlavor === 'elpaso') {
            // @ts-ignore
            return kioskNames;
        }

        Object.entries(kioskNames).forEach(([key, value]) => {
            let obj = {};
            value['computerName'] = key;
            obj = value;

            kioskArray.push(new KioskNameLAX(obj));
        });

        return kioskArray;
    }

    mapCategories(categoriesData: Array<Categories>): Array<MappedCategories> {
        if (environment.appFlavor === 'elpaso') {
            return this.mapPoiCategories(
                categoriesData,
                'categories',
                '_200x200'
            );
        }

        categoriesData = JSON.parse(JSON.stringify(categoriesData));

        const categories: Array<MappedCategories> = JSON.parse(
            JSON.stringify(environment.appCategories)
        );

        for (let i = 0; categoriesData.length > i; i++) {
            switch (categoriesData[i].category) {
                case 'Sky Club':
                    categories[0].map_element_id.push(
                        categoriesData[i].map_element_id
                    );
                    break;

                case 'Restroom':
                    categories[1].map_element_id.push(
                        categoriesData[i].map_element_id
                    );
                    break;

                case 'Amenity':
                    categories[2].map_element_id.push(
                        categoriesData[i].map_element_id
                    );
                    break;

                case 'Drinks':
                    categories[3].map_element_id.push(
                        categoriesData[i].map_element_id
                    );
                    break;

                case 'Shops':
                    categories[4].map_element_id.push(
                        categoriesData[i].map_element_id
                    );
                    break;

                case 'Gates':
                    categories[5].map_element_id.push(
                        categoriesData[i].map_element_id
                    );
                    break;

                case 'Food':
                    categories[6].map_element_id.push(
                        categoriesData[i].map_element_id
                    );
                    break;
                default:
                    break;
            }
        }
        return categories;
    }

    matchSchemaCategories(
        appCategories: Array<MappedCategories>
    ): Array<Categories> {
        const map_categories: Array<Categories> = [];
        appCategories.forEach((node) => {
            node.map_element_id.forEach((mapNode) => {
                map_categories.push(
                    new Categories({
                        category: node.category,
                        map_element_id: mapNode,
                    })
                );
            });
        });

        return map_categories;
    }

    mapConcourses(concourses: Array<Concourses>): Array<Concourses> {
        concourses.forEach((element) => {
            if (Array.isArray(element.gates)) {
                let gates = element.gates;
                for (let i = 0; gates.length > i; i++) {
                    gates = gates.sort((nameA, nameB) => {
                        const valueA = nameA.toLowerCase(); // ignore upper and lowercase
                        const valueB = nameB.toLowerCase(); // ignore upper and lowercase
                        if (valueA < valueB) {
                            return -1;
                        }
                        if (valueA > valueB) {
                            return 1;
                        }
                        // names must be equal
                        return 0;
                    });
                }
                element.gates = gates;
                return concourses;
            } else {
                element.gates = element.gates.split(',');
            }
        });

        return concourses;
    }

    mapHotelsAndAttractionsELP(
        array: Array<NormalizedHotelsAttractionsELPModel>,
        node: string
    ): Array<MappedHotelsAttractionsELPModel> {
        const data = [];
        let pageIcons = [];
        const imagePath =
            node === 'hotels' ? 'hotelImages' : 'attractionImages';

        Promise.all([this.getImagesFromStorage(imagePath)]).then((item) => {
            pageIcons = item[0];
            const rowData = [];
            array.forEach((value) => {
                const icon = pageIcons.find(
                    (res) => res.split('.')[0] === `${value.id}`
                );

                rowData.push(
                    new MappedHotelsAttractionsELPModel({
                        ...value,
                        imageURL: this.getImageURL(imagePath, icon),
                    })
                );
            });

            this.store.dispatch(
                setRowData({
                    node,
                    rowData,
                })
            );
        });

        array.forEach((element) => {
            data.push(element);
        });

        return data;
    }

    mapHotelsAndAttractions(
        data: Array<HotelAndAttraction>,
        node?: string
    ): Array<HotelAndAttraction> {
        if (environment.appFlavor === 'elpaso') {
            // @ts-ignore
            this.mapHotelsAndAttractionsELP(data, node);
        }

        const hotelsArray: Array<HotelAndAttraction> = [];

        if (!data) {
            return hotelsArray;
        }

        Object.entries(data).forEach(([, value]) => {
            let obj = {};
            obj = value;

            hotelsArray.push(new HotelAndAttraction(obj));
        });

        return hotelsArray;
    }

    mappingUsers(userData: UserDataModel): Array<MappedUser> {
        const userArray: Array<MappedUser> = [];

        for (const i in userData) {
            if (userData[i]) {
                let langStr = '';
                if (
                    userData[i].languages &&
                    Array.isArray(userData[i].languages)
                ) {
                    Array.from(userData[i].languages).forEach((lang) => {
                        if (lang === 'en') {
                            langStr = lang + '' + langStr;
                        } else {
                            langStr = langStr + '' + lang;
                        }
                    });
                    if (langStr === '') {
                        userData[i].languages =
                            environment.appFlavor === 'lga' ||
                            environment.appFlavor === 'mea'
                                ? 'en'
                                : 'enes';
                    } else {
                        userData[i].languages = langStr;
                    }
                } else {
                    userData[i].languages =
                        environment.appFlavor === 'lga' ||
                        environment.appFlavor === 'mea'
                            ? 'en'
                            : 'enes';
                }

                userData[i].role = userData[i].role.toLowerCase();

                userArray.push(
                    new MappedUser({
                        creationTime: userData[i].creationTime,
                        disabled: userData[i].disabled,
                        email: userData[i].email,
                        firstName: userData[i].firstName,
                        languages: userData[i].languages.toString(),
                        lastName: userData[i].lastName,
                        lastSignInTime: userData[i].lastSignInTime,
                        role: userData[i].role,
                        providerIDs: userData[i].providerIDs,
                        uid: i,
                    })
                );
            }
        }
        return userArray;
    }

    mappingUsersLanguages(languages: string): Array<string> {
        let langArray: Array<string> = [];

        if (languages) {
            switch (languages) {
                case 'en':
                    langArray = ['en'];
                    break;
                case 'es':
                    langArray = ['es'];
                    break;
                case 'enes':
                    langArray = ['en', 'es'];
                    break;
                default:
                    langArray = ['en'];
                    break;
            }
        } else {
            langArray = ['en', 'es'];
        }

        return langArray;
    }

    mapVideos(videos: Array<VideoModel>): Array<Video> {
        const videosArray: Array<Video> = [];

        if (
            environment.appFlavor === 'elpaso' ||
            environment.appFlavor === 'video-demo'
        ) {
            return videos;
        }

        if (videos && videos.length > 0) {
            Object.entries(videos).forEach(([, value]) => {
                let videoCategory = '';
                videoCategory = value.categoryName;

                if (value?.videos) {
                    Object.entries(value.videos)?.forEach(([, data]) => {
                        let obj = {};
                        data.categoryName = videoCategory;
                        obj = data;
                        videosArray.push(new Video(obj));
                    });
                }
            });

            videosArray.sort((a, b) => {
                if (a.categoryName > b.categoryName) {
                    return 1;
                }
                if (b.categoryName > a.categoryName) {
                    return -1;
                }
                return 0;
            });
        }

        return videosArray;
    }

    mapCategory(category: Array<Category>): Array<MappedCategory> {
        const categoriesArray: Array<MappedCategory> = [];

        if (category?.length) {
            const categoryData: Array<Category> = JSON.parse(
                JSON.stringify(category)
            );
            let videoCategory: { en?: string; es?: string } = {
                en: '',
                es: '',
            };
            let obj: MappedCategory = {};
            let CategoryID: number;
            Object.entries(categoryData).forEach(([, value]) => {
                videoCategory = { en: '', es: '' };

                CategoryID = value['id'];

                if (typeof value['parentCategory'] !== 'object') {
                    const auxObj: { en?: string; es?: string } = {
                        en: '',
                        es: '',
                    };
                    auxObj.en = value.parentCategory;
                    auxObj.es = value.parentCategory;
                    value.parentCategory = auxObj;
                    videoCategory = value.parentCategory;
                } else {
                    videoCategory = value.parentCategory;
                }

                obj = {};
                obj = JSON.parse(JSON.stringify(value));
                obj.parentCategory = videoCategory;
                let tempObj: { en?: string; es?: string } = { en: '', es: '' };
                tempObj.en = '';
                tempObj.es = '';
                obj.category = tempObj;

                obj.isMainCategory = true;
                obj.parentCategoryID = CategoryID;
                obj.categoryID = null;
                //Empty body for parent category and this for consistency between parent category and category
                tempObj = { en: '', es: '' };
                tempObj.en = '';
                tempObj.es = '';
                obj.body = tempObj;

                delete obj['subCategories'];
                delete obj['id'];
                categoriesArray.push(obj);

                if (value.subCategories) {
                    Object.entries(value.subCategories).forEach(([, data]) => {
                        obj = {};
                        obj = JSON.parse(JSON.stringify(data));

                        if (typeof obj.category !== 'object') {
                            const auxObj = {};
                            auxObj['en'] = data.category;
                            auxObj['es'] = data.category;
                            obj.category = auxObj;
                        } else {
                            obj.category = data.category;
                        }

                        obj.parentCategory = videoCategory;
                        obj.isMainCategory = false;
                        obj.parentCategoryID = CategoryID;
                        obj.categoryID = data.id;

                        if (typeof obj.body !== 'object') {
                            const auxObj = {};
                            auxObj['en'] = obj.body || '';
                            auxObj['es'] = obj.body || '';
                            obj.body = auxObj;
                        }

                        delete obj['id'];
                        if (obj['sections']) {
                            delete obj['sections'];
                        }

                        categoriesArray.push(new MappedCategory(obj));
                    });
                }
            });

            categoriesArray.sort((a, b) => {
                if (
                    a.parentCategory.en?.toLowerCase() >
                    b.parentCategory.en?.toLowerCase()
                ) {
                    return 1;
                }
                if (
                    b.parentCategory.en?.toLowerCase() >
                    a.parentCategory.en?.toLowerCase()
                ) {
                    return -1;
                }
                return 0;
            });
        }

        return categoriesArray;
    }

    mapPages(
        pageData: Array<
            | Category
            | MappedMiaPage
            | NormalizePagesELP
            | NormalizePagesAirlineAppModel
        >,
        pageOrder: Array<string> = []
    ): {
        pages: Array<MappedPage | MappedMiaPage | PagesAirlineAppModel>;
        parentPages: Array<MappedMiaPage>;
    } {
        if (!pageData || pageData.length < 1) {
            return { pages: pageData || [], parentPages: [] };
        } else if (environment.appFlavor === 'elpaso') {
            return this.mapPagesELP(pageData as NormalizePagesELP[]);
        } else if (
            environment.appFlavor === 'universal' ||
            environment.appFlavor === 'afw' ||
            environment.appFlavor === 'bwa' ||
            environment.appFlavor === 'mvp'
        ) {
            return this.mapAirlineAppPages(pageData as any, pageOrder) as any;
        }

        let pagesArray = []; // Array<MappedPage | MappedMiaPage>
        let parentPagesArray: Array<MappedMiaPage> = [];

        if (pageData) {
            let categoryId;
            let subcategoryID;
            let category;
            let subcategory;

            const pages = JSON.parse(JSON.stringify(pageData)); //Array<Category | MappedMiaPage>

            if (environment.appFlavor !== 'mia') {
                Object.entries(pages).forEach(([, value]) => {
                    const obj: Category = value;

                    if (typeof obj.parentCategory !== 'object') {
                        const tempObj = {};
                        tempObj['en'] = obj.parentCategory;
                        tempObj['es'] = obj.parentCategory;
                        obj.parentCategory = tempObj;
                        category = obj.parentCategory;
                    } else {
                        category = obj.parentCategory;
                    }

                    categoryId = obj.id;
                    if (obj.subCategories) {
                        Object.entries(obj.subCategories).forEach(
                            ([, entry]) => {
                                const subObj = entry;

                                if (typeof subObj.category !== 'object') {
                                    const tempObj = {};
                                    tempObj['en'] = subObj.category;
                                    tempObj['es'] = subObj.category;
                                    subcategory = tempObj;
                                } else {
                                    subcategory = subObj.category;
                                }

                                subcategoryID = subObj.id;

                                if (subObj.sections) {
                                    Object.entries(subObj.sections).forEach(
                                        ([, data]) => {
                                            const sectionObj = data;

                                            if (
                                                typeof sectionObj.title !==
                                                'object'
                                            ) {
                                                const tempObj = {};
                                                tempObj['en'] =
                                                    sectionObj.title;
                                                tempObj['es'] =
                                                    sectionObj.title;
                                                sectionObj.title = tempObj;
                                            }

                                            if (
                                                typeof sectionObj.body !==
                                                'object'
                                            ) {
                                                const tempObj = {};
                                                tempObj['en'] =
                                                    sectionObj.body || '';
                                                tempObj['es'] =
                                                    sectionObj.body || '';
                                                sectionObj.body = tempObj;
                                            }

                                            let mainobj: MappedPage = {};
                                            mainobj = sectionObj;
                                            mainobj.parentCategoryID =
                                                categoryId;
                                            mainobj.categoryID = subcategoryID;

                                            mainobj.parentCategory = category;
                                            mainobj.category = subcategory;

                                            pagesArray.push(
                                                new MappedPage(mainobj)
                                            );
                                        }
                                    );
                                }
                            }
                        );
                    }
                });

                pagesArray.sort((a, b) => {
                    if (
                        a.category.en.toLowerCase() >
                        b.category.en.toLowerCase()
                    ) {
                        return 1;
                    }
                    if (
                        b.category.en.toLowerCase() >
                        a.category.en.toLowerCase()
                    ) {
                        return -1;
                    }
                    return 0;
                });
            } else {
                const childPages = [];

                Object.entries(pages).forEach(([key, value]) => {
                    const obj: MappedMiaPage = value;

                    let parentPageTitleEN = '';
                    let parentPageID = '';

                    Object.entries(pages).forEach(([parentKey, parent]) => {
                        const parentObj: MappedMiaPage = parent;
                        if (parentKey === obj.parentPage) {
                            parentPageTitleEN = parentObj.title.en;
                            parentPageID = parentKey;
                        }
                    });

                    const page = new MappedMiaPage({
                        ...obj,
                        parentPageTitleEN,
                        pageID: key,
                        parentPageID: parentPageID ? parentPageID : key,
                    });
                    pagesArray.push(page);

                    if (!page.parentPage) {
                        parentPagesArray.push(page);
                    } else {
                        childPages.push(page);
                    }
                });

                childPages.sort((a, b) => {
                    // sort by group
                    const groupComparison = a.parentPageID.localeCompare(
                        b.parentPageID
                    );
                    if (groupComparison !== 0) {
                        return groupComparison;
                    }

                    // sort by index
                    return b.index - a.index;
                });

                parentPagesArray.sort((a, b) => {
                    if (a.index > b.index) {
                        return 1;
                    }
                    if (b.index > a.index) {
                        return -1;
                    }
                    return 0;
                });

                const orderedPages = parentPagesArray.slice();
                const array = [];

                childPages.forEach((page) => {
                    const parentIndex = orderedPages.findIndex(
                        (element) => page.parentPageID === element.pageID
                    );
                    if (parentIndex > -1) {
                        orderedPages.splice(parentIndex + 1, 0, page);
                    } else {
                        array.push(page);
                    }
                });

                array.forEach((element) => {
                    orderedPages.push(element);
                });

                pagesArray = orderedPages.slice();

                parentPagesArray = pagesArray;

                let pageBanners = [];

                let pageIcons = [];

                Promise.all([
                    this.getImagesFromStorage('pageBanners'),
                    this.getImagesFromStorage('pageIcons'),
                ]).then((item) => {
                    pageBanners = item[0];
                    pageIcons = item[1];
                    const rowData = [];
                    pagesArray.forEach((value) => {
                        const image = pageBanners.find(
                            (res) =>
                                res.split('.')[1] === 'webp' &&
                                res.split('.')[0] === `${value.pageID}_1242x863`
                        );
                        const icon = pageIcons.find(
                            (res) =>
                                res.split('.')[1] === 'webp' &&
                                res.split('.')[0] === `${value.pageID}_300x300`
                        );

                        rowData.push(
                            new MappedMiaPage({
                                ...value,
                                banner: this.getImageURL('pageBanners', image),
                                icon: this.getImageURL('pageIcons', icon),
                            })
                        );
                    });

                    this.store.dispatch(
                        setRowData({
                            node: 'pages',
                            rowData,
                        })
                    );
                });
            }
        }

        return { pages: pagesArray, parentPages: parentPagesArray };
    }

    mapSurvey(surveyData: Survey): Array<MappedSurvey> {
        const surveyArray: Array<MappedSurvey> = [];

        if (surveyData && Object.keys(surveyData).length > 0) {
            const survey: Survey = JSON.parse(JSON.stringify(surveyData));

            Object.entries(survey).forEach(([key, value]) => {
                if (value.questions) {
                    value.questions.forEach((question) => {
                        if (question) {
                            surveyArray.push(
                                new MappedSurvey({
                                    type: key,
                                    question: {
                                        en: question.en || '',
                                        es: question.es || '',
                                    },
                                })
                            );
                        }
                    });
                }
            });

            surveyArray.sort((a, b) => {
                if (a.type.toLowerCase() > b.type.toLowerCase()) {
                    return 1;
                }
                if (b.type.toLowerCase() > a.type.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }

        return surveyArray;
    }

    mapCategoryDropDown(
        category: Array<Category>
    ): Array<MappedCategoryDropDown> {
        const categoriesArray: Array<MappedCategoryDropDown> = [];

        if (category) {
            let CategoryID: number;

            const categoryData: Array<Category> = JSON.parse(
                JSON.stringify(category)
            );
            const obj: MappedCategoryDropDown = {};
            let videoCategory: { en?: string; es?: string } = {
                en: '',
                es: '',
            };
            let subCategoryArray = [];

            Object.entries(categoryData).forEach(([, value]) => {
                subCategoryArray = [];
                videoCategory = { en: '', es: '' };

                CategoryID = value['id'];

                if (typeof value.parentCategory !== 'object') {
                    const tempObj = {};
                    tempObj['en'] = value.parentCategory;
                    tempObj['es'] = value.parentCategory;
                    obj.parentCategory = tempObj;
                } else {
                    obj.parentCategory = value.parentCategory;
                }
                videoCategory = value.parentCategory;

                if (value.subCategories) {
                    Object.entries(value.subCategories).forEach(([, entry]) => {
                        const subObj: SubCategoryDropDown = JSON.parse(
                            JSON.stringify(entry)
                        );

                        if (typeof entry.category !== 'object') {
                            const tempObj: { en?: string; es?: string } = {
                                en: '',
                                es: '',
                            };
                            tempObj['en'] = entry.category;
                            tempObj['es'] = entry.category;
                            subObj.category = tempObj;
                        } else {
                            subObj.category = entry.category;
                        }

                        subObj.parentCategory = videoCategory;
                        subObj.parentCategoryID = CategoryID;

                        subObj.categoryID = entry.id;

                        delete subObj['id'];

                        if (subObj['sections']) {
                            delete subObj['sections'];
                        }

                        subCategoryArray.push(new SubCategoryDropDown(subObj));
                    });
                }
                categoriesArray.push(
                    new MappedCategoryDropDown({
                        parentCategory: videoCategory,
                        parentCategoryID: CategoryID,
                        subCategories: subCategoryArray,
                    })
                );
            });
        }

        return categoriesArray;
    }

    normalizeVideos(
        videoData: NormalizeVideosDataModel
    ): Array<NormalizeVideo> {
        let videosArray: Array<Video> = [];
        const resultArray: Array<NormalizeVideo> = [];
        if (videoData) {
            for (var prop in videoData) {
                if (prop) {
                    videosArray = [];
                    videoData[prop].forEach((element) => {
                        videosArray.push(new Video(element));
                    });
                    const elem: NormalizeVideo = {
                        categoryName: prop,
                        videos: videosArray,
                    };
                    resultArray.push(new NormalizeVideo(elem));
                }
            }
        }
        return resultArray;
    }

    normalizeVideosELP(videosArray: Array<Video>): Array<Video> {
        videosArray.forEach((element) => {
            delete element.featured;
        });

        return videosArray;
    }

    normalizeCategory(
        categoryData: NormalizeCategoryModel,
        pages: Array<MappedPage>
    ): Array<Category> {
        const resultArray: Array<Category> = [];
        let sections: Array<Section> = [];
        let categoriesArray = [];
        let categoryName = {};

        //loop on parent category
        if (categoryData) {
            for (const prop in categoryData) {
                if (prop) {
                    categoriesArray = [];
                    //loop on subCategories
                    categoryData[prop].forEach((element) => {
                        //if is parent category
                        if (element.category.en === '') {
                            categoryName = element.parentCategory;
                        }

                        //if is subcategories
                        if (element.category.en !== '') {
                            sections = [];

                            //loop on sections
                            pages.forEach((page) => {
                                if (element.categoryID === page.categoryID) {
                                    let sectionObj = {};
                                    sectionObj = {
                                        body: page.body || { en: '', es: '' },
                                        id: page.id,
                                        title: page.title,
                                    };
                                    sections.push(sectionObj);
                                }
                            });

                            const subCategoryID = element.categoryID;
                            element['id'] = subCategoryID;

                            delete element['isMainCategory'];
                            delete element['categoryID'];
                            delete element['parentCategoryID'];
                            delete element['parentCategory'];

                            const elemData = {
                                id:
                                    typeof subCategoryID === 'number'
                                        ? subCategoryID
                                        : parseInt(subCategoryID, 10),
                                category: element.category,
                                body: element.body,
                                sections,
                            };
                            categoriesArray.push(elemData);
                        }
                    });

                    const elem = {
                        parentCategory: categoryName,
                        id: parseInt(prop, 10),
                        subCategories: categoriesArray,
                    };

                    resultArray.push(new Category(elem));
                }
            }
        }

        return resultArray;
    }

    normalizeSurveyConfig(
        surveyConfigData: Survey,
        surveyData: Array<MappedSurvey>
    ): Survey {
        let surveyArray: Array<MappedSurvey> = [];
        let surveyConfig: Survey = new Survey({});
        const persistentSurveyQuestions = [];
        const postCallSurveyQuestions = [];

        surveyArray = surveyData ? JSON.parse(JSON.stringify(surveyData)) : [];

        if (surveyArray && Array.isArray(surveyData) && surveyData.length > 0) {
            surveyData.forEach((survey) => {
                if (survey.type === 'persistentSurvey') {
                    persistentSurveyQuestions.push(survey.question);
                } else {
                    postCallSurveyQuestions.push(survey.question);
                }
            });
        }

        surveyConfig = new Survey(
            surveyConfigData ? JSON.parse(JSON.stringify(surveyConfigData)) : {}
        );
        if (surveyConfigData) {
            surveyConfig.persistentSurvey.questions =
                persistentSurveyQuestions || [];
            surveyConfig.postCallSurvey.questions =
                postCallSurveyQuestions || [];
        }

        return surveyConfig;
    }

    groupBy(data: any, key: string): any {
        // `data` is an array of objects, `key` is the key (or property accessor) to group by
        // reduce runs this anonymous function on each element of `data` (the `item` parameter,
        // returning the `storage` parameter at the end
        if (data) {
            return data.reduce((groupData, item) => {
                // get the first instance of the key by which we're grouping
                const group = item[key];

                // set `videoData` for this instance of group to the outer scope (if not empty) or initialize it
                groupData[group] = groupData[group] || [];

                // add this item to its group within `videoData`
                groupData[group].push(item);

                // return the updated videoData to the reduce function, which will then loop through the next
                return groupData;
            }, {}); // {} is the initial value of the videoData
        } else {
            return {};
        }
    }

    mapLaxDataOnSaving(contentData: DataModel, node: string): Object {
        const content = contentData ? _.cloneDeep(contentData) : {};

        let nodeData = node && contentData[node] ? contentData[node] : '';

        if (node === 'kioskNames') {
            const kioskNamesArray = {};
            content.kioskname.forEach((kioskName) => {
                kioskNamesArray[kioskName.computerName] = kioskName;
            });

            nodeData = kioskNamesArray;
        }

        if (node === 'idleState') {
            const idleState = this.normalizeIdleState(content.idleState);

            nodeData = idleState;
        }

        if (node === 'surveyConfig') {
            const survey = this.normalizeSurveyConfig(
                content.survey_config,
                content.survey
            );
            nodeData = survey;
        }

        if (node === 'pages') {
            const category = this.normalizeCategory(
                this.groupBy(content.category, 'parentCategoryID'),
                content.pages
            );
            nodeData = category;
        }

        if (node === 'videos') {
            const videos = this.normalizeVideos(
                this.groupBy(content.videos, 'categoryName')
            );
            nodeData = videos;
        }

        if (node === 'emergencyBroadcast') {
            nodeData = content.emergencybroadcast;
        }

        if (node === 'pois') {
            nodeData = content.laxpois;
        }

        let body = {};
        if (node) {
            body = {
                [node]: nodeData,
            };
        }

        return body;
    }

    mapELPasoDataOnSaving(contentData: DataModel, node: string): Object {
        let nodeData = node && contentData[node] ? contentData[node] : '';

        const content = contentData ? _.cloneDeep(contentData) : {};
        let body = {};

        if (node === 'categories') {
            nodeData = this.normalizeMiaPoiCategories(content.categories);
        } else if (node === 'pois') {
            nodeData = this.normalizeELPasoPois(content.pois);
        } else if (node === 'hotels' || node === 'attractions') {
            nodeData = this.normalizeHotelsAttractions(
                node === 'hotels' ? content.hotels : content.attractions
            );
        } else if (node === 'pages') {
            nodeData = this.NormalizePagesELP(content.pages);
        } else if (node === 'videos') {
            nodeData = this.normalizeVideosELP(content.videos);
        }

        if (node && nodeData) {
            body = {
                [node]: nodeData,
            };
        }

        return body;
    }

    mapMiaDataOnSaving(
        contentData: DataModel,
        node: string,
        nodeApi?: string
    ): Object {
        let nodeData = node && contentData[node] ? contentData[node] : '';
        let newSavingWay = false;
        const content = contentData ? _.cloneDeep(contentData) : {};
        let body = {};

        if (node === 'tags') {
            nodeData =
                nodeApi && nodeApi === 'contents/saveContents'
                    ? this.normalizeMiaPoiTags(nodeData)
                    : this.apiRequestData.action === 'DELETE'
                    ? null
                    : this.normalizeMiaPoiTags([this.apiRequestData.data])?.[0];
            if (nodeApi && nodeApi === 'contents/saveContents') {
                newSavingWay = false;
            } else {
                newSavingWay = true;
            }
        } else if (node === 'poiCategories') {
            nodeData =
                this.apiRequestData.action === 'DELETE'
                    ? null
                    : this.normalizeMiaPoiCategories([
                          this.apiRequestData.data,
                      ])?.[0];
            newSavingWay = true;
        } else if (node === 'cities') {
            nodeData =
                this.apiRequestData.action === 'DELETE'
                    ? null
                    : this.apiRequestData.data;
            newSavingWay = true;
        } else if (node === 'pages') {
            nodeData = this.normalizeMiaPages(content.pages);
        } else if (node === 'airlines') {
            nodeData = this.normalizeMiaAirlines(content.airlines);
        } else if (node.toLowerCase() === 'broadcasts') {
            nodeData = this.NormalizeSendNotification(nodeData, nodeApi);
        } else if (node === 'topics') {
            nodeData = this.NormalizeNotifications(nodeData);
        } else if (node === 'geofences') {
            nodeData = this.normalizeGeoFences(nodeData);
        } else if (node === 'pois') {
            nodeData =
                this.apiRequestData.action === 'DELETE'
                    ? null
                    : this.normalizeMiaPois([this.apiRequestData.data])?.[0];
            newSavingWay = true;
        } else if (node === 'config') {
            nodeData = this.NormalizeConfigFormData(content.config);
        } else if (node === 'notifications') {
            nodeData = _.cloneDeep(content.notifications);
        }

        if (node && !newSavingWay) {
            if (node.toLowerCase() === 'broadcasts') {
                if (nodeApi === 'notification/sendMessage') {
                    let _n = _.cloneDeep(nodeData);
                    // let topicsArr = [];

                    // for (let i = 0; i < _n.topics.length; i++) {
                    //     const element = _n.topics[i];
                    //     if (element.key) {
                    //         topicsArr.push(element.key);
                    //     } else {
                    //         topicsArr.push(element);
                    //     }
                    // }

                    if (_n.image) {
                        body = {
                            ..._n,
                            image: _n.image.replace('.webp', '.jpeg'),
                        };
                    } else {
                        body = {
                            ..._n,
                        };
                    }
                } else {
                    body = {
                        notifications: nodeData,
                    };
                }
            } else if (node === 'pages') {
                body = {
                    pages: nodeData.pages,
                    pagesOrdering: nodeData.pagesOrdering,
                };
            } else {
                body = {
                    [node]: nodeData,
                };
            }
        } else {
            body = {
                ...this.apiRequestData,
                data: nodeData,
            };
        }

        return body;
    }

    mapAirlineAppDataOnSaving(
        contentData: DataModel,
        node: string,
        nodeApi?: string
    ): Object {
        let nodeData = node && contentData[node] ? contentData[node] : '';
        let newSavingWay = false;
        let body = {};

        if (node === 'pages') {
            nodeData = this.NormalizePagesAirlineApp(nodeData);
            newSavingWay = false;
        } else if (node === 'banners') {
            nodeData =
                this.apiRequestData.action === 'DELETE'
                    ? null
                    : this.NormalizeBannersAirlineApp([
                          this.apiRequestData.data,
                      ]);
            newSavingWay = true;
        } else if (node.toLowerCase() === 'morelinks') {
            nodeData =
                nodeApi && nodeApi === 'contents/saveContents'
                    ? this.NormalizeMoreLinksAirlineApp(nodeData)
                    : this.apiRequestData.action === 'DELETE'
                    ? null
                    : this.NormalizeMoreLinksAirlineApp([
                          this.apiRequestData.data,
                      ]);
            newSavingWay = !nodeApi || nodeApi !== 'contents/saveContents';
        } else if (node.toLowerCase() === 'featuredcountries') {
            nodeData =
                this.apiRequestData.action === 'DELETE'
                    ? null
                    : this.NormalizeFeaturedCountriesAirlineApp([
                          this.apiRequestData.data,
                      ]);
            newSavingWay = true;
        } else if (node === 'offers') {
            nodeData = this.NormalizeOffersAirlineApp(nodeData);
            newSavingWay = false;
        } else if (node.toLowerCase() === 'introslides') {
            nodeData =
                nodeApi && nodeApi === 'contents/saveContents'
                    ? this.NormalizeIntroSlidesAirlineApp(nodeData)
                    : this.apiRequestData.action === 'DELETE'
                    ? null
                    : this.NormalizeIntroSlidesAirlineApp([
                          this.apiRequestData.data,
                      ]);
            newSavingWay = !nodeApi || nodeApi !== 'contents/saveContents';
        } else if (node === 'airports') {
            nodeData = this.NormalizeAirportsAirlineApp(nodeData);
            newSavingWay = false;
        } else if (node === 'equipment') {
            nodeData = this.NormalizeEquipmentAirlineApp(nodeData);
            newSavingWay = false;
        } else if (node === 'fares') {
            nodeData = this.NormalizeFaresAirlineApp(nodeData);
            newSavingWay = false;
        } else if (node.toLowerCase() === 'broadcasts') {
            nodeData = this.NormalizeSendNotification(nodeData, nodeApi);
            newSavingWay = false;
        } else if (node === 'geofences') {
            nodeData = this.normalizeGeoFences(nodeData);
            newSavingWay = false;
        } else if (node === 'topics') {
            nodeData = this.NormalizeNotifications(nodeData);
            newSavingWay = false;
        } else if (node.toLowerCase() === 'farefields') {
            nodeData = this.NormalizeFareFieldsAirlineApp(nodeData);
            newSavingWay = false;
        } else if (node.toLowerCase() === 'importantreminders') {
            nodeData = this.normalizeRemindersAirlineApp(nodeData);
            newSavingWay = false;
        }

        if (node && !newSavingWay) {
            if (node.toLowerCase() === 'broadcasts') {
                if (nodeApi === 'notification/sendMessage') {
                    let _n = _.cloneDeep(nodeData);

                    if (_n.image) {
                        body = {
                            ..._n,
                            image: _n.image.replace('.webp', '.jpeg'),
                        };
                    } else {
                        body = {
                            ..._n,
                        };
                    }
                } else {
                    body = {
                        notifications: nodeData,
                    };
                }
            } else if (node === 'pages') {
                body = {
                    pages: nodeData.pages,
                    pageOrder: nodeData.pagesOrdering,
                };
            } else {
                body = {
                    [node]: nodeData,
                };
            }
        } else {
            body = {
                ...this.apiRequestData,
                data: nodeData ? nodeData[0] : {},
            };
        }

        return body;
    }

    mapLGASavingData(content: DataModel, node: string): Object {
        let nodeData = node && content[node] ? content[node] : {};

        if (node === 'gate_headers') {
            const _g = content.concourses
                ? JSON.parse(JSON.stringify(content.concourses))
                : [];
            for (const i in _g) {
                if (_g) {
                    _g[i].gates = _g[i].gates.join();
                }
            }
            nodeData = _g;
        }

        if (node === 'icons') {
            const mappedIcons = [];
            if (content.icons) {
                content.icons.forEach((element) => {
                    mappedIcons.push({
                        SVG: element.SVG,
                        Text: element.Text || '',
                        keyword: element.keyword,
                    });
                });
            }
            nodeData = mappedIcons;
        }

        if (node === 'kiosk_names') {
            const mappedKiosk = {};
            if (content.kioskNames) {
                content.kioskNames.map((data) => {
                    mappedKiosk[data.computer_name] = {
                        map_element_id: data.map_element_id,
                        orientation: data.orientation,
                        landside: data.landside,
                    };
                });
            }
            nodeData = mappedKiosk;
        }

        let body = {};
        if (node === 'map_categories') {
            nodeData = this.matchSchemaCategories(content.categories);

            body = {
                map_categories: nodeData,
                background_categories: content.background_categories,
            };
        } else if (node) {
            body = {
                [node]: nodeData,
            };
        }

        return body;
    }

    mapNeomSavingData(contentData: DataModel, node: string): Object {
        const content = contentData ? _.cloneDeep(contentData) : {};
        let nodeData = node && content[node] ? content[node] : {};

        if (node === 'pages') {
            const category = this.normalizeCategory(
                this.groupBy(content.category, 'parentCategoryID'),
                content.pages
            );
            nodeData = category;
        }

        if (node === 'videos') {
            const videos = this.normalizeVideos(
                this.groupBy(content.videos, 'categoryName')
            );
            nodeData = videos;
        }

        if (node === 'kiosk_names') {
            const mappedKiosk = [];
            if (content.kioskNames) {
                content.kioskNames.forEach((element) => {
                    mappedKiosk.push({
                        map_element_id: element.map_element_id,
                        orientation: element.orientation,
                        computer_name: element.computer_name,
                    });
                });
            }
            nodeData = mappedKiosk;
        }

        let body = {};
        if (node) {
            body = {
                [node]: nodeData,
            };
        }

        return body;
    }

    mapAgents(agents: any): Array<Agents> {
        const agentsArray: Array<Agents> = [];

        if (agents) {
            Object.entries(agents).forEach(([key, valueObj]) => {
                const status = key;

                Object.entries(valueObj).forEach(([, value]) => {
                    agentsArray.push(
                        new Agents({
                            ...value,
                            status,
                            timestamp: value.timestamp
                                ? this.dateTimeService.formatDate(
                                      value.timestamp,
                                      'MMMM D, YYYY LT'
                                  )
                                : '',
                        })
                    );
                });
            });
        }

        return agentsArray;
    }

    //MEA mapping functions
    mapMeaSavingData(contentData: DataModel, node: string): Object {
        const content = contentData ? _.cloneDeep(contentData) : {};
        let nodeData = node && content[node] ? content[node] : {};
        let bodyData = {};

        if (node === 'specialUplift' || node === 'preOrderSaleableItems') {
            let specialUpliftArray = [];
            let preOrderSaleableArray = [];

            nodeData = content['distributionLists'];
            if (nodeData) {
                nodeData.forEach((element) => {
                    if (element && element.dbNode === 'specialUplift') {
                        specialUpliftArray = element.emails || [];
                    }

                    if (element && element.dbNode === 'preOrderSaleableItems') {
                        preOrderSaleableArray = element.emails || [];
                    }
                });
            }

            if (node === 'specialUplift') {
                bodyData = {
                    forms: {
                        specialUplift: {
                            distributionList: specialUpliftArray,
                            config: {
                                options: content.specialUplift || [],
                            },
                        },
                    },
                };
            } else {
                bodyData = {
                    forms: {
                        preOrderSaleableItems: {
                            distributionList: preOrderSaleableArray,
                        },
                    },
                };
            }
        } else if (node === 'templates') {
            bodyData = content;
        }

        return bodyData;
    }

    mapDistributionList(data: DistributionList): Array<MappedDistributionList> {
        const formattedArray: Array<MappedDistributionList> = [];
        if (data) {
            for (const i in data) {
                if (data) {
                    const obj = {
                        distributionListName:
                            i === 'preOrderSaleableItems'
                                ? this.translate.instant(
                                      'TABLE_CONFIG.PRE_ORDER_SALEABLE'
                                  )
                                : i === 'specialUplift'
                                ? this.translate.instant(
                                      'TABLE_CONFIG.SPECIAL_UPLIFT'
                                  )
                                : i,
                        emails: data[i].distributionList,
                        dbNode: i,
                    };
                    formattedArray.push(new MappedDistributionList(obj));
                }
            }
        }

        return formattedArray;
    }

    mapFlightsList(data: Array<Flight>): Array<MappedFlight> {
        const flightArray: Array<MappedFlight> = [];
        if (data) {
            data.forEach((item) => {
                const flight = new Flight(item);
                const associatedList = [];
                if (flight.associatedFlight.length > 0) {
                    associatedList.push(
                        new AssociatedFlight({
                            ...flight,
                            departureTime: flight.depScheduled
                                ? this.dateTimeService.formatDate(
                                      flight.depScheduled,
                                      'HH:mm'
                                  )
                                : '',
                        })
                    );
                    flight.associatedFlight.forEach((associatedItem) => {
                        associatedList.push(
                            new AssociatedFlight({
                                ...associatedItem,
                                departureTime: associatedItem.depScheduled
                                    ? this.dateTimeService.formatDate(
                                          associatedItem.depScheduled,
                                          'HH:mm'
                                      )
                                    : '',
                            })
                        );
                    });
                }
                flightArray.push(
                    new MappedFlight({
                        ...flight,
                        flightNumber: `${flight.operatingAirline} ${flight.flightNumber} ${flight.departureAirportCode} - ${flight.arrivalAirportCode}`,
                        flightUUID: flight.flightUuid,
                        flightValue: `${
                            flight.localDepartureDate
                        } | ${this.dateTimeService.formatDate(
                            flight.depScheduled,
                            'HH:mm'
                        )} | ${flight.operatingAirline}${
                            flight.flightNumber
                        } | ${flight.departureAirportCode} → ${
                            flight.arrivalAirportCode
                        }`,
                        flightNum: flight.flightNumber,
                        departureTime: flight.depScheduled
                            ? this.dateTimeService.formatDate(
                                  flight.depScheduled,
                                  'HH:mm'
                              )
                            : '',
                        associatedFlight: associatedList,
                    })
                );
            });
        }

        return flightArray;
    }

    formatRequests(data: Array<Requests>): Array<MappedRequest> {
        let formattedData: Array<MappedRequest> = [];
        if (data) {
            data.map((element) => {
                formattedData.push({
                    ...element,
                    activity: this.formatActivity(element.activity),
                });
            });

            formattedData = formattedData.sort((flightA, flightB) => {
                if (
                    this.dateTimeService.getUnix(flightA.localDepartureDate) ===
                    this.dateTimeService.getUnix(flightB.localDepartureDate)
                ) {
                    if (
                        this.dateTimeService.getUnix(flightA.timestamp) ===
                        this.dateTimeService.getUnix(flightB.timestamp)
                    ) {
                        return 0;
                    } else {
                        if (
                            this.dateTimeService.getUnix(flightA.timestamp) >
                            this.dateTimeService.getUnix(flightB.timestamp)
                        ) {
                            return -1;
                        }
                        if (
                            this.dateTimeService.getUnix(flightA.timestamp) <
                            this.dateTimeService.getUnix(flightB.timestamp)
                        ) {
                            return 1;
                        }
                    }
                } else {
                    if (
                        this.dateTimeService.getUnix(
                            flightA.localDepartureDate
                        ) <
                        this.dateTimeService.getUnix(flightB.localDepartureDate)
                    ) {
                        return 1;
                    }
                    if (
                        this.dateTimeService.getUnix(
                            flightA.localDepartureDate
                        ) >
                        this.dateTimeService.getUnix(flightB.localDepartureDate)
                    ) {
                        return -1;
                    }
                }
            });
        }

        return formattedData;
    }

    formatActivity(activities: Array<Activity>): Array<MappedActivity> {
        const formattedActivities: Array<MappedActivity> = [];

        if (activities && Array.isArray(activities)) {
            activities.map((data) => {
                if (data.action === 'CREATE') {
                    formattedActivities.push({
                        icon: 'icofont icofont-clock-time',
                        label: 'Request Created',
                        who: data.who,
                        date: this.dateTimeService.formatDate(
                            data.activityDate,
                            'MM/DD/YYYY hh:mm:a'
                        ),
                        action: data.action,
                    });
                } else if (data.action === 'UPDATE') {
                    formattedActivities.push({
                        icon: 'icofont icofont-ui-edit',
                        label: 'Request Updated',
                        who: data.who,
                        date: this.dateTimeService.formatDate(
                            data.activityDate,
                            'MM/DD/YYYY hh:mm:a'
                        ),
                        action: data.action,
                    });
                } else if (data.action === 'CANCEL') {
                    formattedActivities.push({
                        icon: 'icofont icofont-ui-block',
                        label: 'Request Canceled',
                        who: data.who,
                        date: this.dateTimeService.formatDate(
                            data.activityDate,
                            'MM/DD/YYYY hh:mm:a'
                        ),
                        action: data.action,
                        reason: data.reason || '',
                    });
                } else if (data.action === 'DECLINE') {
                    formattedActivities.push({
                        icon: 'icofont icofont-thumbs-down',
                        label: 'Request Declined',
                        who: data.who,
                        date: this.dateTimeService.formatDate(
                            data.activityDate,
                            'MM/DD/YYYY hh:mm:a'
                        ),
                        action: data.action,
                        reason: data.reason || '',
                    });
                } else if (data.action === 'RECOVER') {
                    formattedActivities.push({
                        icon: 'icofont icofont-redo',
                        label: 'Request Recovered',
                        who: data.who,
                        date: this.dateTimeService.formatDate(
                            data.activityDate,
                            'MM/DD/YYYY hh:mm:a'
                        ),
                    });
                } else if (data.action === 'APPROVE') {
                    formattedActivities.push({
                        icon: 'icofont icofont-check-circled',
                        label: 'Request Approved',
                        who: data.who,
                        date: this.dateTimeService.formatDate(
                            data.activityDate,
                            'MM/DD/YYYY hh:mm:a'
                        ),
                        action: data.action,
                    });
                }
            });
        }

        return formattedActivities.reverse();
    }

    mapTemplates(template: Array<Templates> = []): Array<Templates> {
        const mappedTemplates: Array<Templates> = [];

        template.forEach((item) => {
            mappedTemplates.push(new Templates(item));
        });

        return mappedTemplates;
    }

    mapAirports(
        flightData: Array<Flight> = []
    ): Array<{ key: string; label: string }> {
        let airports = [];

        if (flightData && Array.isArray(flightData) && flightData.length > 0) {
            flightData.map((flight) => {
                if (
                    !airports.find(
                        (node) => node.key === flight.arrivalAirportCode
                    )
                ) {
                    airports.push({
                        key: flight.arrivalAirportCode,
                        label: flight.arrivalAirportCode,
                    });
                }
            });

            airports = this.utilityService.sortArrayOfObjects(airports, 'key');
        }

        return airports;
    }

    mapInventories(
        inventories: FlightInventories[]
    ): MappedFlightInventories[] {
        const inventoriesArray = [];

        if (
            inventories &&
            Array.isArray(inventories) &&
            inventories.length > 0
        ) {
            inventories.forEach((element) => {
                const inventoryContainers = [];
                const containers = [];

                element.containers.forEach((container) => {
                    inventoryContainers.push(
                        `${container.contentsType} | ${container.containerPosition}`
                    );
                    containers.push(new TemplateContainers(container));
                });
                const associatedList = [];

                associatedList.push(
                    new AssociatedFlight({
                        ...element,
                        localDepartureDate: element.flightDate,
                        flightInfo: `${element.flightNumber} ${element.departureAirportCode} - ${element.arrivalAirportCode}`,
                    })
                );

                if (element.associatedFlight?.length > 0) {
                    element.associatedFlight.forEach((associatedItem) => {
                        associatedList.push(
                            new AssociatedFlight({
                                ...associatedItem,
                                departureTime: associatedItem.depScheduled
                                    ? this.dateTimeService.formatDate(
                                          associatedItem.depScheduled,
                                          'HH:mm'
                                      )
                                    : '',
                                depScheduled: associatedItem.depScheduled
                                    ? this.dateTimeService.formatDate(
                                          associatedItem.depScheduled,
                                          'YYYY-MM-DD'
                                      )
                                    : '',
                                flightInfo: `${associatedItem.operatingAirline}${associatedItem.flightNumber} ${associatedItem.departureAirportCode} - ${associatedItem.arrivalAirportCode}`,
                            })
                        );
                    });
                }

                const arrivalAirportCode =
                    element.arrivalAirportCode &&
                    Array.isArray(element.arrivalAirportCode) &&
                    element.arrivalAirportCode.length > 0
                        ? element.arrivalAirportCode[0]
                        : element.arrivalAirportCode || '';

                const obj = new MappedFlightInventories({
                    ...element,
                    inventoryContainers,
                    containers,
                    flightInfo: `${element.flightNumber} ${element.departureAirportCode} - ${arrivalAirportCode}`,
                    flightUUID: element.flightUuid,
                    lastUpdated: this.dateTimeService.formatDate(
                        element.lastUpdated,
                        'MMMM D, YYYY LT'
                    ),
                    associatedFlight: associatedList,
                });
                inventoriesArray.push(obj);
            });
        }

        return inventoriesArray;
    }

    getInventoriesRowsTemplate(
        data: MappedFlightInventories[]
    ): CustomExportTemplate[] {
        const rowsTemplate = [];

        if (data) {
            data.forEach((element) => {
                element.associatedFlight.forEach((associatedFlight) => {
                    element.containers.forEach((item) => {
                        const contentsArray = [];
                        item.contents.forEach((content) => {
                            contentsArray.push(
                                `${content.quantity}-${content.item}`
                            );
                        });

                        const associatedFlights = [];
                        element.associatedFlight.forEach((value) => {
                            if (
                                value.flightUuid !== associatedFlight.flightUuid
                            ) {
                                associatedFlights.push(
                                    `${value.operatingAirline}${value.flightNumber}`
                                );
                            }
                        });

                        rowsTemplate.push([
                            {
                                data: {
                                    value: `${associatedFlight.operatingAirline}${associatedFlight.flightNumber} ${associatedFlight.localDepartureDate}`,
                                    type: 'String',
                                },
                            },
                            {
                                data: {
                                    value: `${item.containerType}-${item.containerPosition}`,
                                    type: 'String',
                                },
                            },
                            {
                                data: {
                                    value: item.seal,
                                    type: 'String[]',
                                },
                            },
                            {
                                data: {
                                    value: contentsArray,
                                    type: 'String[]',
                                },
                            },
                            {
                                data: {
                                    value: associatedFlights,
                                    type: 'String[]',
                                },
                            },
                        ]);
                    });
                });
                element.containers.forEach((item) => {
                    const contentsArray = [];
                    item.contents.forEach((content) => {
                        contentsArray.push(
                            `${content.quantity}-${content.item}`
                        );
                    });

                    rowsTemplate.push([
                        {
                            data: {
                                value: `${element.flightNumber} ${element.flightDate}`,
                                type: 'String',
                            },
                        },
                        {
                            data: {
                                value: `${item.containerType}-${item.containerPosition}`,
                                type: 'String',
                            },
                        },
                        {
                            data: {
                                value: item.seal,
                                type: 'String[]',
                            },
                        },
                        {
                            data: {
                                value: contentsArray,
                                type: 'String[]',
                            },
                        },
                    ]);
                });
            });
        }

        return rowsTemplate;
    }

    mapPagesELP(pagesArray: Array<NormalizePagesELP>): any {
        let pageImages = [];
        const data = [];

        Promise.all([this.getImagesFromStorage('pageImages')]).then((item) => {
            pageImages = item[0];
            const rowData = [];
            pagesArray.forEach((value) => {
                const image = pageImages.find(
                    (res) => res.split('.')[0] === `${value.key}`
                );

                rowData.push(
                    new MappedPagesELP({
                        ...value,
                        image: this.getImageURL('pageImages', image),
                    })
                );
            });

            this.store.dispatch(
                setRowData({
                    node: 'pages',
                    rowData,
                })
            );
        });

        pagesArray.forEach((element) => {
            data.push(element);
        });

        return data;
    }

    mapTags(tagsArray: any): any {
        if (environment.appFlavor !== 'mia') {
            return tagsArray;
        }

        let tagIcons = [];
        const data = [];

        Promise.all([this.getImagesFromStorage('tagIcons')]).then((item) => {
            tagIcons = item[0];
            const rowData = [];
            tagsArray.forEach((value) => {
                const icon = tagIcons.find(
                    (res) =>
                        res.split('.')[1] === 'webp' &&
                        res.split('.')[0] === `${value.key}_300x300`
                );

                rowData.push(
                    new MappedMiaPoiTags({
                        ...value,
                        icon: this.getImageURL('tagIcons', icon),
                    })
                );
            });

            this.store.dispatch(
                setRowData({
                    node: 'tags',
                    rowData,
                })
            );
        });

        tagsArray.forEach((element) => {
            data.push(element);
        });

        return data;
    }

    normalizeMiaPoiTags(
        poiCategoriesData: Array<MappedMiaPoiTags>
    ): Array<NormalizeMiaPoiTags> {
        const nodeData: Array<NormalizeMiaPoiTags> = [];

        if (poiCategoriesData) {
            poiCategoriesData.forEach((value) => {
                nodeData.push(new NormalizeMiaPoiTags(value));
            });
        }

        return nodeData;
    }

    getMappedAirlines(airlines: AirlinesAPIDataModel): Array<{
        code: string;
        name: string;
        hidden: boolean;
        content: Array<ContentModel>;
    }> {
        const mappedAirlines = Object.entries(airlines).map(([key, value]) => {
            return {
                code: key || '',
                name: value?.name || '',
                hidden: value?.hidden || false,
                content: value?.content || [],
                icon: value?.icon || '',
                image: value?.image || '',
            };
        });
        return mappedAirlines;
    }

    mapMIAConfig(configObj: ConfigFormModel): ConfigFormInputsModel {
        const mappedConfig = {};

        if (configObj.contentTypes?.doorCoordinates) {
            Object.keys(configObj.contentTypes.doorCoordinates).forEach(
                (key) => {
                    if (key) {
                        mappedConfig[key] =
                            configObj.contentTypes.doorCoordinates[key];
                    }
                }
            );
        }

        return mappedConfig;
    }

    NormalizeConfigFormData(form: any): any {
        const doorCoordinates = {
            arrivalDoors: [],
            departureDoors: [],
        };

        ['departure', 'arrival'].forEach((element) => {
            if (form[element + 'Doors']?.length > 0) {
                form[element + 'Doors'].forEach((coord) => {
                    doorCoordinates[element + 'Doors'].push(
                        new DoorCoordinateSectionModel(coord)
                    );
                });
            }
        });

        const mappedConfigObj = new ConfigFormModel({
            arrivalDoors: doorCoordinates.arrivalDoors,
            departureDoors: doorCoordinates.departureDoors,
        });

        return mappedConfigObj;
    }

    mapAirlineData(
        field: string,
        data: { [key: string]: any } = {}
    ): { [key: string]: any } {
        const returnObj = {};

        returnObj[data[field]] = data;
        delete data[field];

        return returnObj;
    }

    mapMiaAirlines(data: { [key: string]: NormalizeMiaAirline } = {}): {
        [key: string]: MappedMiaAirline;
    } {
        if (environment.appFlavor === 'video-demo') {
            return;
        }

        const returnObj = {};
        let airlineBanners = [];

        let airlineLogos = [];

        Promise.all([
            this.getImagesFromStorage('airlineBanners'),
            this.getImagesFromStorage('airlineLogos'),
        ]).then((item) => {
            airlineBanners = item[0];
            airlineLogos = item[1];
            const rowData = {};
            Object.entries(data).forEach(([key, value]) => {
                const image = airlineBanners.find(
                    (res) =>
                        res.split('.')[1] === 'webp' &&
                        res.split('.')[0] === `${key}_1242x863`
                );
                const logo = airlineLogos.find(
                    (res) =>
                        res.split('.')[1] === 'webp' &&
                        res.split('.')[0] === `${key}_300x300`
                );

                rowData[key] = new MappedMiaAirline({
                    ...value,
                    code: key,
                    image: this.getImageURL('airlineBanners', image),
                    icon: this.getImageURL('airlineLogos', logo),
                });
            });

            this.store.dispatch(
                setRowData({
                    node: 'airlines',
                    rowData,
                })
            );

            setTimeout(() => {
                this.eventHubService.triggerImageLoaded.emit();
            }, 1000);
        });

        Object.entries(data).forEach(([key, value]) => {
            returnObj[key] = new MappedMiaAirline({
                ...value,
                code: key,
                image: '',
                icon: '',
            });
        });

        return returnObj;
    }

    async getImagesFromStorage(path: string): Promise<any> {
        const storage = getStorage();

        const storageRef = ref(storage, path);
        const images = [];

        await listAll(storageRef).then((item) => {
            item.items.forEach((res) => {
                images.push(res.name);
            });
        });

        return await images;
    }

    normalizeMiaPages(pageData: Array<MappedMiaPage>): {
        pages: { [key: string]: NormalizeMiaPage };
        pagesOrdering: Array<NormalizeMiaPage>;
    } {
        const nodeData = {};
        const pagesOrdering = [];

        if (pageData) {
            const pages: Array<MappedMiaPage> = JSON.parse(
                JSON.stringify(pageData)
            );

            pages.forEach((element: MappedMiaPage, index: number) => {
                const actions = element.actions.map(
                    (item) => new PageActionsModel(item)
                );

                const page = new NormalizeMiaPage({
                    ...element,
                    actions,
                });
                nodeData[element.pageID] = { ...page, index };
                pagesOrdering.push(element.pageID);
            });
        }
        return { pages: nodeData, pagesOrdering };
    }

    normalizeMiaAirlines(airlineData: { [key: string]: MappedMiaAirline }): {
        [key: string]: NormalizeMiaAirline;
    } {
        const nodeData = {};

        if (airlineData) {
            const airlines: { [key: string]: MappedMiaAirline } = JSON.parse(
                JSON.stringify(airlineData)
            );

            Object.entries(airlines).forEach(([key, value]) => {
                nodeData[key] = new NormalizeMiaAirline(value);
            });
        }

        return nodeData;
    }

    mapPois(pois: Array<any>): Array<any> {
        let data = [];
        if (environment.appFlavor === 'mia') {
            let poiBanners = [];
            let poiIcons = [];

            pois.forEach((element) => {
                data.push({
                    ...element,
                    description: _.isPlainObject(element.description)
                        ? element.description
                        : { en: element.description, es: element.description },
                    name: _.isPlainObject(element.name)
                        ? element.name
                        : { en: element.name, es: element.name },
                });
            });

            Promise.all([
                this.getImagesFromStorage('poiBanners'),
                this.getImagesFromStorage('poiIcons'),
            ]).then((item) => {
                poiBanners = item[0];
                poiIcons = item[1];
                const rowData = [];
                data.forEach((value) => {
                    const image = poiBanners.find(
                        (res) =>
                            res.split('.')[1] === 'webp' &&
                            res.split('.')[0] === `${value.key}_1242x863`
                    );
                    const icon = poiIcons.find(
                        (res) =>
                            res.split('.')[1] === 'webp' &&
                            res.split('.')[0] === `${value.key}_300x300`
                    );

                    rowData.push(
                        new MappedMiaPoi({
                            ...value,
                            image: this.getImageURL('poiBanners', image),
                            icon: this.getImageURL('poiIcons', icon),
                            operatingHours: this.mapOperatingHours(
                                value.operatingHours
                            ),
                        })
                    );
                });

                this.store.dispatch(
                    setRowData({
                        node: 'pois',
                        rowData,
                    })
                );
            });
        } else if (environment.appFlavor === 'elpaso') {
            let pageIcons = [];

            Promise.all([this.getImagesFromStorage('poiIcons')]).then(
                (item) => {
                    pageIcons = item[0];
                    const rowData = [];
                    pois.forEach((value) => {
                        const icon = pageIcons.find(
                            (res) =>
                                res.split('.')[1] === 'webp' &&
                                res.split('.')[0] === `${value.key}_300x300`
                        );

                        rowData.push({
                            ...value,
                            icon: this.getImageURL('poiIcons', icon),
                            operatingHours: this.mapOperatingHours(
                                value.operatingHours
                            ),
                        });
                    });

                    this.store.dispatch(
                        setRowData({
                            node: 'pois',
                            rowData,
                        })
                    );
                }
            );

            pois.forEach((element) => {
                data.push(element);
            });
        } else {
            data = pois;
        }

        return data;
    }

    mapOperatingHours(
        array: Array<OperatingHoursDataModel>
    ): Array<OperatingHoursRecordsModel> {
        const operatingHours = [];

        array?.forEach((element) => {
            const days = [];

            for (let index = 0; index <= element.day.length - 1; index++) {
                days.push(
                    this.utilityService.weekdaysArray[
                        +element.day.charAt(index) - 1
                    ]
                );
            }

            operatingHours.push(
                new OperatingHoursRecordsModel({
                    selectedDaysArray: days,
                    openTime: element.openingTime,
                    closeTime: element.closingTime,
                    date: this.utilityService.convertWeekDaysArrayToText(days),
                    time: `${this.utilityService.convertTimeTo12HourFormat(
                        element.openingTime
                    )} – ${this.utilityService.convertTimeTo12HourFormat(
                        element.closingTime
                    )}`,
                    isEntireDayHours:
                        element.openingTime === '00:00' &&
                        element.closingTime === '23:59',
                })
            );
        });

        return operatingHours;
    }

    normalizeMiaPois(poiData: Array<MappedMiaPoi>): Array<NormalizeMiaPoi> {
        const nodeData: Array<NormalizeMiaPoi> = [];

        if (poiData) {
            poiData.forEach((value) => {
                const poiObj = new NormalizeMiaPoi(value);

                poiObj.operatingHours = this.normalizeOperatingHours(
                    poiObj.operatingHours
                );

                nodeData.push(poiObj);
            });
        }

        return nodeData;
    }

    mapPoiCategories(
        categories: Array<any>,
        node: string,
        imageSize = '_300x300'
    ): Array<any> {
        let data = [];
        if (
            environment.appFlavor === 'mia' ||
            environment.appFlavor === 'elpaso'
        ) {
            let pageIcons = [];

            Promise.all([this.getImagesFromStorage('poiCategoryIcons')]).then(
                (item) => {
                    pageIcons = item[0];
                    const rowData = [];
                    categories.forEach((value) => {
                        const icon = pageIcons.find(
                            (res) =>
                                res.split('.')[1] === 'webp' &&
                                res.split('.')[0] === `${value.key}${imageSize}`
                        );

                        rowData.push(
                            new MappedMiaPoiCategories({
                                ...value,
                                icon: this.getImageURL(
                                    'poiCategoryIcons',
                                    icon
                                ),
                            })
                        );
                    });

                    this.store.dispatch(
                        setRowData({
                            node,
                            rowData,
                        })
                    );
                }
            );

            categories.forEach((element) => {
                data.push(element);
            });
        } else {
            data = categories;
        }

        return data;
    }

    normalizeHotelsAttractions(
        poisData: Array<MappedHotelsAttractionsELPModel>
    ): Array<NormalizedHotelsAttractionsELPModel> {
        const nodeData: Array<NormalizedHotelsAttractionsELPModel> = [];

        if (poisData) {
            poisData.forEach((value) => {
                nodeData.push(new NormalizedHotelsAttractionsELPModel(value));
            });
        }

        return nodeData;
    }

    normalizeOperatingHours(
        operatingHours: Array<OperatingHoursRecordsModel>
    ): Array<OperatingHoursRecordsModel> {
        const openingHours = [];

        operatingHours?.forEach((element) => {
            let dayStr = '';
            element.selectedDaysArray?.forEach((day) => {
                dayStr += this.utilityService.weekdaysArray.indexOf(day) + 1;
            });

            openingHours.push(
                new OperatingHoursDataModel({
                    openingTime: element.isEntireDayHours
                        ? '00:00'
                        : element.openTime,
                    closingTime: element.isEntireDayHours
                        ? '23:59'
                        : element.closeTime,
                    day: dayStr,
                })
            );
        });

        return openingHours;
    }

    normalizeELPasoPois(
        poisData: Array<MappedPoisELPModel>
    ): Array<NormalizedPoisELPModel> {
        const nodeData: Array<NormalizedPoisELPModel> = [];

        if (poisData) {
            poisData.forEach((value) => {
                const poi = new NormalizedPoisELPModel(value);

                poi.operatingHours = this.normalizeOperatingHours(
                    poi.operatingHours
                );

                nodeData.push(poi);
            });
        }

        return nodeData;
    }

    normalizeMiaPoiCategories(
        poiCategoriesData: Array<MappedMiaPoiCategories>
    ): Array<NormalizeMiaPoiCategories> {
        const nodeData: Array<NormalizeMiaPoiCategories> = [];

        if (poiCategoriesData) {
            poiCategoriesData.forEach((value) => {
                nodeData.push(new NormalizeMiaPoiCategories(value));
            });
        }

        return nodeData;
    }

    NormalizePagesELP(
        pagesData: Array<MappedPagesELP>
    ): Array<NormalizePagesELP> {
        const nodeData: Array<NormalizePagesELP> = [];

        if (pagesData) {
            pagesData.forEach((value) => {
                nodeData.push(new NormalizePagesELP(value));
            });
        }

        return nodeData;
    }

    getImageURL(basePath: string, imageName: string): string {
        return imageName && basePath
            ? `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/${basePath}%2F${imageName}?alt=media&token=${this.userToken}`
            : null;
    }

    mapHomeBanners(): HomeBannerModel {
        const data: HomeBannerModel = new HomeBannerModel();
        if (environment.appFlavor === 'mia') {
            let images = [];

            Promise.all([this.getImagesFromStorage('homeBanners')]).then(
                (item) => {
                    images = item[0];
                    let home = '';
                    let flights = '';
                    let explore = '';
                    let defaultPOI = '';

                    images.forEach((image) => {
                        if (image.split('.')[0] === 'home') {
                            home = image;
                        }

                        if (image.split('.')[0] === 'flights') {
                            flights = image;
                        }

                        if (image.split('.')[0] === 'explore') {
                            explore = image;
                        }

                        if (image.split('.')[0] === 'defaultPOI') {
                            defaultPOI = image;
                        }
                    });

                    this.store.dispatch(
                        setRowData({
                            node: 'home_banners',
                            rowData: new HomeBannerModel({
                                home: home
                                    ? this.getImageURL('homeBanners', home)
                                    : '',
                                flights: flights
                                    ? this.getImageURL('homeBanners', flights)
                                    : '',
                                explore: explore
                                    ? this.getImageURL('homeBanners', explore)
                                    : '',
                                defaultPOI: defaultPOI
                                    ? this.getImageURL(
                                          'homeBanners',
                                          defaultPOI
                                      )
                                    : '',
                            }),
                        })
                    );
                }
            );
        }

        return data;
    }

    mapAirlineAppPages(
        data: {
            [key: string]: NormalizePagesAirlineAppModel;
        },
        pageOrder: Array<string>
    ): PagesAirlineAppModel[] {
        const rowData = [];
        Object.keys(data).forEach((key) => {
            data[key]['index'] = pageOrder.findIndex((id) => id === key) - 1;
        });

        let pageBanners = [];

        Promise.all([this.getImagesFromStorage('pageBanners')]).then((item) => {
            pageBanners = item[0];

            Object.keys(data).forEach((pageID) => {
                const banner = pageBanners.find(
                    (res) =>
                        res.split('.')[1] === 'webp' &&
                        res.split('.')[0] === `${pageID}_300x300`
                );
                rowData.push(
                    new PagesAirlineAppModel({
                        ...data[pageID],
                        pageID,
                        banner: this.getImageURL('pageBanners', banner),
                    })
                );
            });

            rowData.sort((a, b) => {
                if (a.index > b.index) {
                    return 1;
                }
                if (b.index > a.index) {
                    return -1;
                }
                return 0;
            });

            this.store.dispatch(
                setRowData({
                    node: 'pages',
                    rowData,
                })
            );

            setTimeout(() => {
                this.eventHubService.triggerImageLoaded.emit();
            }, 1000);
        });

        return rowData;
    }

    NormalizePagesAirlineApp(data: PagesAirlineAppModel[]): {
        pages: { [key: string]: NormalizePagesAirlineAppModel };
        pagesOrdering: Array<NormalizePagesAirlineAppModel>;
    } {
        const nodeData = {};
        const pagesOrdering = [];

        if (data) {
            data.forEach((element: PagesAirlineAppModel) => {
                const actions = [];

                element.actions?.forEach((item) => {
                    if (item.type && (item.value || item.content)) {
                        actions.push(new PageActionsAirlineAppModel(item));
                    }
                });

                nodeData[element.pageID] = new NormalizePagesAirlineAppModel({
                    ...element,
                    actions,
                });
                pagesOrdering.push(element.pageID);
            });
        }

        return { pages: nodeData, pagesOrdering };
    }

    mapAirlineAppBanners(
        data: BannerAirlineAppModel[] = []
    ): BannerAirlineAppModel[] {
        const returnData = [];

        let banners = [];

        Promise.all([this.getImagesFromStorage('banners')]).then((item) => {
            banners = item[0];

            const rowData = [];
            data?.forEach((value) => {
                const logo = banners.find(
                    (res) =>
                        res.split('.')[1] === 'webp' &&
                        res.split('.')[0] === `${value.key}_300x300`
                );

                rowData.push(
                    new BannerAirlineAppModel({
                        ...value,
                        image: this.getImageURL('banners', logo),
                    })
                );
            });

            this.store.dispatch(
                setRowData({
                    node: 'banners',
                    rowData,
                })
            );

            setTimeout(() => {
                this.eventHubService.triggerImageLoaded.emit();
            }, 1000);
        });

        data?.forEach((value) => {
            returnData.push(
                new BannerAirlineAppModel({
                    ...value,
                    image: '',
                })
            );
        });

        return returnData;
    }

    NormalizeBannersAirlineApp(
        data: BannerAirlineAppModel[]
    ): Array<NormalizeAirlineAppBannerModel> {
        const nodeData: Array<NormalizeAirlineAppBannerModel> = [];

        if (data) {
            data.forEach((value) => {
                nodeData.push(new NormalizeAirlineAppBannerModel(value));
            });
        }

        return nodeData;
    }

    mapAirlineAppOffers(
        data: OfferAirlineAppModel[] = []
    ): OfferAirlineAppModel[] {
        const returnData = [];

        let offers = [];

        Promise.all([this.getImagesFromStorage('offers')]).then((item) => {
            offers = item[0];

            const rowData = [];
            data?.forEach((value) => {
                const logo = offers.find(
                    (res) =>
                        res.split('.')[1] === 'webp' &&
                        res.split('.')[0] ===
                            `${value.destinationAirport}_300x300`
                );

                rowData.push(
                    new OfferAirlineAppModel({
                        ...value,
                        image: this.getImageURL('offers', logo),
                    })
                );
            });

            this.store.dispatch(
                setRowData({
                    node: 'offers',
                    rowData,
                })
            );

            setTimeout(() => {
                this.eventHubService.triggerImageLoaded.emit();
            }, 1000);
        });

        data?.forEach((value) => {
            returnData.push(
                new OfferAirlineAppModel({
                    ...value,
                    image: '',
                })
            );
        });

        return returnData;
    }

    NormalizeOffersAirlineApp(
        data: OfferAirlineAppModel[]
    ): Array<NormalizeOfferAirlineAppModel> {
        const nodeData: Array<NormalizeOfferAirlineAppModel> = [];

        if (data) {
            data.forEach((value) => {
                nodeData.push(
                    new NormalizeOfferAirlineAppModel({
                        ...value,
                        travelPeriodInbound: {
                            from:
                                value.typeOfTrip === OfferTripTypeEnum.ONE_WAY
                                    ? ''
                                    : value.travelPeriodInbound?.from
                                    ? this.dateTimeService.formatDate(
                                          value.travelPeriodInbound.from,
                                          'M-D-YYYY',
                                          true
                                      ) || ''
                                    : '',
                            to:
                                value.typeOfTrip === OfferTripTypeEnum.ONE_WAY
                                    ? ''
                                    : value.travelPeriodInbound?.to
                                    ? this.dateTimeService.formatDate(
                                          value.travelPeriodInbound.to,
                                          'M-D-YYYY',
                                          true
                                      ) || ''
                                    : '',
                        },
                        travelPeriodOutbound: {
                            from: value.travelPeriodOutbound?.from
                                ? this.dateTimeService.formatDate(
                                      value.travelPeriodOutbound.from,
                                      'M-D-YYYY',
                                      true
                                  ) || ''
                                : '',
                            to: value.travelPeriodOutbound?.to
                                ? this.dateTimeService.formatDate(
                                      value.travelPeriodOutbound.to,
                                      'M-D-YYYY',
                                      true
                                  ) || ''
                                : '',
                        },
                        offerValidity: {
                            from: value.offerValidity?.from
                                ? this.dateTimeService.formatDate(
                                      value.offerValidity.from,
                                      'M-D-YYYY',
                                      true
                                  ) || ''
                                : '',
                            to: value.offerValidity?.to
                                ? this.dateTimeService.formatDate(
                                      value.offerValidity.to,
                                      'M-D-YYYY',
                                      true
                                  ) || ''
                                : '',
                        },
                    })
                );
            });
        }

        return nodeData;
    }

    NormalizeIntroSlidesAirlineApp(
        data: Array<MappedIntroSlidesAirlineAppModel>
    ): Array<NormalizedIntroSlidesAirlineAppModel> {
        const slidesData = [];

        data?.forEach((element) => {
            slidesData.push(
                new NormalizedIntroSlidesAirlineAppModel({
                    ...element,
                    color:
                        element.backgroundType === 'color' ||
                        !element.backgroundType
                            ? element.color
                            : '',
                })
            );
        });

        return slidesData;
    }

    mapIntroSlidesAirlineApp(
        data: Array<NormalizedIntroSlidesAirlineAppModel>
    ): Array<MappedIntroSlidesAirlineAppModel> {
        const rowData = [];

        let images = [];
        let backgroundCovers = [];

        Promise.all([
            this.getImagesFromStorage('introSlidesImages'),
            this.getImagesFromStorage('introSlidesCovers'),
        ]).then((item) => {
            images = item[0];
            backgroundCovers = item[1];

            data?.forEach((element) => {
                const image = images.find(
                    (res) =>
                        res.split('.')[1] === 'webp' &&
                        res.split('.')[0] === `${element.key}_300x300`
                );

                const cover = backgroundCovers.find(
                    (res) =>
                        res.split('.')[1] === 'webp' &&
                        res.split('.')[0] === `${element.key}_300x300`
                );

                rowData.push(
                    new MappedIntroSlidesAirlineAppModel({
                        ...element,
                        image: this.getImageURL('introSlidesImages', image),
                        cover: this.getImageURL('introSlidesCovers', cover),
                    })
                );
            });

            this.store.dispatch(
                setRowData({
                    node: 'introSlides',
                    rowData,
                })
            );

            setTimeout(() => {
                this.eventHubService.triggerImageLoaded.emit();
            }, 1000);
        });

        return rowData;
    }

    mapAirlineAppAirports(
        data:
            | { [key: string]: AirportAirlineAppModel }
            | AirportAirlineAppModel[] // later this data type should be removed
    ): AirportAirlineAppModel[] {
        if (!data) {
            return [];
        }
        const returnData = [];

        let airports = [];

        if (Array.isArray(data)) {
            data.forEach((value) => {
                returnData.push(
                    new AirportAirlineAppModel({
                        ...value,
                        image: '',
                    })
                );
            });
        } else {
            Object.entries(data).forEach(([key, value]) => {
                returnData.push(
                    new AirportAirlineAppModel({
                        ...value,
                        image: '',
                        airportCode: key,
                    })
                );
            });
        }

        Promise.all([this.getImagesFromStorage('airports')]).then((item) => {
            airports = item[0];

            const rowData = [];
            returnData?.forEach((value) => {
                const logo = airports.find(
                    (res) =>
                        res.split('.')[1] === 'webp' &&
                        res.split('.')[0] === `${value.airportCode}_300x300`
                );

                rowData.push(
                    new AirportAirlineAppModel({
                        ...value,
                        image: this.getImageURL('airports', logo),
                    })
                );
            });

            this.store.dispatch(
                setRowData({
                    node: 'airports',
                    rowData,
                })
            );

            setTimeout(() => {
                this.eventHubService.triggerImageLoaded.emit();
            }, 1000);
        });

        return returnData;
    }

    NormalizeAirportsAirlineApp(data: AirportAirlineAppModel[]): {
        [key: string]: NormalizeAirportAirlineAppModel;
    } {
        const nodeData: { [key: string]: NormalizeAirportAirlineAppModel } = {};

        if (data) {
            data.forEach((value) => {
                nodeData[value.airportCode] =
                    new NormalizeAirportAirlineAppModel(value);
            });
        }

        return nodeData;
    }

    mapAirlineAppEquipment(data: {
        [key: string]: any;
    }): EquipmentAirlineAppModel[] {
        const returnData = [];

        Object.entries(data).forEach(([key, value]) => {
            returnData.push(
                new EquipmentAirlineAppModel({ key, value: value?.toString() })
            );
        });

        return returnData;
    }

    NormalizeEquipmentAirlineApp(data: Array<EquipmentAirlineAppModel>): {} {
        const nodeData = {};

        if (data && data.length) {
            data.forEach((item) => {
                nodeData[item.key] = item.value;
            });
        }

        return nodeData;
    }

    mapAirlineAppFares(data: { [key: string]: any }): FaresAirlineAppModel[] {
        const returnData = [];

        Object.entries(data).forEach(([key, value]) => {
            const fareFieldsLabels = [];
            const fareFieldsItems = [];

            const rules = value?.rules || {};
            Object.keys(rules)?.forEach((ruleType) => {
                const fieldData =
                    this.utilityService.databusContent.fareFields.find(
                        (element) => element.key === ruleType
                    );

                if (fieldData) {
                    fareFieldsLabels.push(fieldData.name.en);
                }
                const fareFieldsItemsValue = {};
                environment.formLanguages.forEach((node) => {
                    fareFieldsItemsValue[node.key] = rules[ruleType]?.value[
                        node.key
                    ]
                        ? rules[ruleType]?.value[node.key]
                        : rules[ruleType]?.value.en
                        ? rules[ruleType]?.value.en
                        : '';
                });

                fareFieldsItems.push({
                    type: ruleType,
                    value: fareFieldsItemsValue,
                });
            });

            returnData.push(
                new FaresAirlineAppModel({
                    key,
                    fareFieldsLabels,
                    fareFieldsItems,
                    ...value,
                })
            );
        });

        return returnData;
    }

    NormalizeFaresAirlineApp(data: Array<FaresAirlineAppModel>): {} {
        const nodeData = {};

        data?.forEach((item) => {
            const rules = {};

            item.fareFieldsItems?.forEach((element) => {
                if (element?.type && element.value?.en) {
                    const fareFieldsItemsValue = {};
                    environment.formLanguages.forEach((node) => {
                        fareFieldsItemsValue[node.key] = element.value[node.key]
                            ? element.value[node.key]
                            : element.value.en
                            ? element.value.en
                            : '';
                    });
                    rules[element.type] = {
                        value: fareFieldsItemsValue,
                    };
                }
            });

            nodeData[item.key] = {
                cabin: item.cabin,
                name: item.name,
                rules,
            };
        });

        return nodeData;
    }

    mapBroadcasts(
        notifications: Array<NormalizedBroadcastsModel>
    ): Array<NormalizedBroadcastsModel> {
        const array = [];

        notifications.forEach((element) => {
            const topicsWithCount = [];
            const topics = [];

            if (element.topics) {
                Object.entries(element.topics)?.forEach(([key, val]) => {
                    topicsWithCount.push(`${key} (${val})`);
                    topics.push(key);
                });
            }

            array.push({
                ...element,
                topics,
                topicsWithCount,
            });
        });

        return array;
    }

    async getImageById(
        validationForm: FormGroup,
        uploadBasePath: string
    ): Promise<any> {
        let images = [];

        return await Promise.all([
            this.getImagesFromStorage('notifications'),
        ]).then((item) => {
            images = item[0];

            const img = images.find(
                (res) =>
                    res.split('.')[1] === 'webp' &&
                    res.split('.')[0] === `${validationForm.value.id}_300x300`
            );
            return this.getImageURL(uploadBasePath, img);
        });
    }

    NormalizeSendNotification(data: any, nodeApi: string): any {
        // behavior for '/sendMessage' api
        if (nodeApi === 'notification/sendMessage') {
            const notification = data[data.length - 1];

            const channel = [];
            if (notification.androidUsers) {
                channel.push('android');
            }

            if (notification.appleUsers) {
                channel.push('ios');
            }
            const sendMessage = {
                title: notification.title,
                body: notification.body,
                topics: notification.topics || [],
                token: this.userToken,
                notificationID: notification.id,
                metadata: {
                    channel,
                },
            };

            if (
                notification.toggleScheduledTime === true &&
                notification.scheduledTime
            ) {
                sendMessage['scheduledTime'] = this.dateTimeService.formatDate(
                    notification.scheduledTime,
                    'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
                    true,
                    true
                );
            }

            if (notification.image) {
                sendMessage['image'] = notification.image;
            }

            return sendMessage;
        }

        // behavior for `/contents/saveContents` api
        const array = [];

        data.forEach((element) => {
            const topicsWithCount = {};
            element.topics?.forEach((topic) => {
                const flight =
                    this.utilityService.databusContent?.flightNotifications?.find(
                        (item) => item.key === topic
                    );

                if (
                    this.utilityService.topicSubscribers &&
                    this.utilityService.topicSubscribers[topic]
                ) {
                    topicsWithCount[topic] =
                        this.utilityService.topicSubscribers[topic];
                } else if (flight) {
                    topicsWithCount[topic] = flight.count || 0;
                } else {
                    topicsWithCount[topic] = 0;
                }
            });

            array.push(
                new NormalizedBroadcastsModel({
                    ...element,
                    expiryDate:
                        element.expiryDate ||
                        this.dateTimeService.getAddedDays(90),
                    timestamp:
                        element.timestamp ||
                        this.dateTimeService.getAddedDays(0),
                    topics: topicsWithCount,
                    scheduledTime:
                        element.toggleScheduledTime === true
                            ? this.dateTimeService.formatDate(
                                  element.scheduledTime,
                                  'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
                                  true,
                                  true
                              )
                            : element.toggleScheduledTime === false
                            ? ''
                            : element.scheduledTime || '',
                })
            );
        });

        return array;
    }

    mapTopicsAirlineApp(
        topics: Array<NormalizedTopicsModel>
    ): Array<MappedTopicsModel> {
        const topicsArray = [];

        Object.keys(topics).forEach((key) => {
            topicsArray.push({
                label: topics[key].name?.en,
                key,
                name: topics[key].name,
                description: topics[key].description,
            });
        });

        this.store.dispatch(
            setRowData({
                node: 'topics',
                rowData: topicsArray,
            })
        );

        return topicsArray;
    }

    mapTopicsSubscribers(
        topics: Array<MappedTopicsModel>,
        topicSubscribers: any
    ): Array<MappedTopicsModel> {
        const notifications = topics.slice();

        let data = [];

        Promise.all([this.getImagesFromStorage('topics')]).then((item) => {
            data = item[0];

            notifications?.forEach((element) => {
                const image = data.find(
                    (res) =>
                        res.split('.')[1] === 'webp' &&
                        res.split('.')[0] === `${element.key}_300x300`
                );

                element['count'] = topicSubscribers[element.key] || 0;
                element['image'] = this.getImageURL('topics', image);
            });

            this.store.dispatch(
                setRowData({
                    node: 'topics',
                    rowData: notifications,
                })
            );
        });

        return notifications;
    }

    mapFlightNotifications(data: any): any {
        const array = [];

        Object.keys(data).forEach((key) => {
            array.push({
                ...data[key],
                key,
            });
        });

        this.store.dispatch(
            setRowData({
                node: 'flightNotifications',
                rowData: array,
            })
        );

        return array;
    }

    NormalizeNotifications(
        data: Array<MappedTopicsModel>
    ): NormalizedTopicsModel {
        const topics = {};

        data.forEach((element) => {
            const nameObj = {};
            const descriptionObj = {};

            Object.keys(element.name).forEach((key) => {
                nameObj[key] = element.name[key]
                    ? element.name[key]
                    : element.name.en;
            });

            Object.keys(element.description).forEach((key) => {
                descriptionObj[key] = element.description[key]
                    ? element.description[key]
                    : element.description.en;
            });
            topics[element.key] = {
                name: nameObj,
                description: descriptionObj,
            };
        });

        return topics;
    }

    mapChatbotLogs(logs: { [key: string]: ChatbotLogs }): Array<ChatbotLogs> {
        const mappedLogs = [];

        Object.keys(logs)?.forEach((key) => {
            mappedLogs.push(new ChatbotLogs(logs[key]));
        });

        return mappedLogs;
    }

    mapFareFieldsAirlineApp(fareFields: {
        [key: string]: NormalizedFareFieldsAirlineAppModel;
    }): Array<MappedFareFieldsAirlineAppModel> {
        const rowData = [];

        Object.keys(fareFields)?.forEach((key) => {
            rowData.push(
                new MappedFareFieldsAirlineAppModel({
                    ...fareFields[key],
                    key,
                })
            );
        });

        return rowData;
    }

    NormalizeFareFieldsAirlineApp(
        data: Array<MappedFareFieldsAirlineAppModel>
    ): {
        [key: string]: NormalizedFareFieldsAirlineAppModel;
    } {
        const fareFields = {};

        data?.forEach((element) => {
            fareFields[element.key] = new NormalizedFareFieldsAirlineAppModel(
                element
            );
        });

        return fareFields;
    }

    NormalizeMoreLinksAirlineApp(
        data: Array<MoreLinksModel>
    ): Array<MoreLinksModel> {
        const returnData = [];

        data?.forEach((element) => {
            const actions = element.actions?.map(
                (item) => new PageActionsAirlineAppModel(item)
            );

            returnData.push(
                new MoreLinksModel({
                    ...element,
                    actions,
                })
            );
        });

        return returnData;
    }

    NormalizeFeaturedCountriesAirlineApp(
        data: Array<FeaturedCountriesAirlineAppModel>
    ): Array<FeaturedCountriesAirlineAppModel> {
        return data?.map(
            (element) => new FeaturedCountriesAirlineAppModel(element)
        );
    }

    mapGeoFences(
        data: Array<NormalizeGeoFencesModel>
    ): Array<MappedGeoFencesModel> {
        const array: Array<MappedGeoFencesModel> = [];

        Object.keys(data)?.forEach((key) => {
            array.push({
                ...data[key],
                id: key,
                title: data[key].alert.title || { en: '', es: '' },
                subtitle: data[key].alert.subtitle || { en: '', es: '' },
                radius: data[key].radius ? +data[key].radius : null,
            });
        });

        return array;
    }

    normalizeGeoFences(data: Array<MappedGeoFencesModel>): {
        [key: string]: NormalizeGeoFencesModel;
    } {
        const obj: { [key: string]: NormalizeGeoFencesModel } = {};

        data?.forEach((element) => {
            obj[element.id] = new NormalizeGeoFencesModel(element);
        });

        return obj;
    }

    normalizeRemindersAirlineApp(data: { main: string }): { main: string } {
        if (data.main === '<p></p>') {
            return { main: '' };
        }

        return data;
    }

    mapIdleState(data: any, videos: any): any {
        if (!data) {
            return [];
        }
        const arr = [];

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            let obj = { ...element };

            if (obj.type === 'image') {
                obj = {
                    ...obj,
                    image: this.getImageURL(
                        'idleState',
                        `${obj.key}_1920x1080.webp`
                    ),
                    title: '-',
                };
            } else {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let j = 0; j < videos.length; j++) {
                    const elementJ = videos[j];

                    if (element.url === elementJ.videoURL) {
                        obj = {
                            ...obj,
                            image: elementJ.thumbnailURL,
                            title: elementJ.videoTitle,
                        };
                    }
                }
            }

            arr.push(obj);
        }

        return arr;
    }

    normalizeIdleState(data: any): any {
        const arr = [];

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < data.length; i++) {
            const element = data[i];

            arr.push({
                playLength: parseInt(element.playLength, 10),
                mimeType: element.mimeType,
                type: element.type,
                url: element.url ? element.url : null,
                key: element.key,
            });
        }

        return arr;
    }
}
