import { AssociatedFlight } from './services/mappingModels';

export class DialogMessageModel {
    viewButtons?: Array<string>;
    header?: string;
    body?: string;
    data?: any;
    associatedFlights?: AssociatedFlight[]; // For the associated flight of the Flight Inventory
    carts?: any;
    containers?: any;

    constructor(props: DialogMessageModel) {
        return {
            viewButtons: props.viewButtons || [],
            header: props.header || '',
            body: props.body || '',
            data: props.data,
            associatedFlights:
                props.associatedFlights &&
                Array.isArray(props.associatedFlights)
                    ? props.associatedFlights
                    : [],
            carts: props.carts,
            containers: props.containers,
        };
    }
}
