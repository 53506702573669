export class DoorCoordinateSectionModel {
    key: string;
    lat: string;
    lng: string;

    constructor(props: DoorCoordinateSectionModel) {
        return {
            key: props.key || '',
            lat: props.lat || '',
            lng: props.lng || '',
        };
    }
}

export class ConfigFormModel {
    contentTypes?: {
        doorCoordinates?: {
            arrivalDoors?: Array<DoorCoordinateSectionModel>;
            departureDoors?: Array<DoorCoordinateSectionModel>;
        };
    };

    constructor(props: ConfigFormInputsModel) {
        return {
            contentTypes: {
                doorCoordinates: {
                    arrivalDoors: props.arrivalDoors || [],
                    departureDoors: props.departureDoors || [],
                },
            },
        };
    }
}
export class ConfigFormInputsModel {
    arrivalDoors?: Array<DoorCoordinateSectionModel>;
    departureDoors?: Array<DoorCoordinateSectionModel>;
}
