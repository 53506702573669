import { AirlinesModel, HomeBannerModel } from './services/mappingModels';
import { ConfigFormModel } from './config-form.model';

export class DataBusModel {
    content: ContentModel;
    requestForm: {
        approved: {}[];
        canceled: {}[];
        declined: {}[];
        pending: {}[];
    };
    selectedRow: {
        node: string;
        rowData: {};
        nodeType: string;
    };
    filteredValue: {
        node: string;
        filterValue: string;
        rowIndex: number;
        pageNumber: number;
        sorting?: any;
    };
    agents: {}[];
    carts: {}[];
    selectedInventoryTemplate: {}[];
    inventoriesLastHours: string;
    inventoriesLastDays: string;
    apiRequestData?: any;
    lastModified?: string;

    constructor() {
        return {
            content: new ContentModel(),
            carts: [],
            requestForm: {
                approved: [],
                canceled: [],
                declined: [],
                pending: [],
            },
            selectedRow: {
                node: '',
                rowData: {},
                nodeType: '',
            },
            filteredValue: {
                node: '',
                filterValue: '',
                rowIndex: null,
                pageNumber: 0,
                sorting: null,
            },
            agents: [],
            selectedInventoryTemplate: [],
            inventoriesLastHours: '',
            inventoriesLastDays: '',
            apiRequestData: {},
            lastModified: null,
        };
    }
}

export class ContentModel {
    background_categories: {};
    destinations: {}[];
    kioskNames: {}[];
    categories: {}[];
    transport: {}[];
    icons: {}[];
    pois: {}[];
    concourses: {}[];
    users: {}[];
    hotels: {}[];
    laxpois: {}[];
    attractions: {}[];
    videos: {}[];
    idleState: {}[];
    category: {}[];
    pages: {}[];
    pagesOrdering: {}[];
    category_dropdown: {}[];
    emergency: {};
    emergencybroadcast: {};
    poiCategories: {}[];
    survey: {}[];
    survey_config: {};
    kioskname: {}[];
    distributionLists: {}[];
    specialUplift: [];
    flights: {}[];
    templates: {}[];
    airports: {}[];
    inventories: {}[];
    mapElementIDs: string[];
    tags: {}[];
    airlines: AirlinesModel;
    parentPages: {}[];
    cities: {}[];
    config: ConfigFormModel;
    home_banners: HomeBannerModel;
    banners?: {}[];
    offers?: {}[];
    equipment?: {}[];
    fares?: {}[];
    terms?: {};
    importantReminders?: {};
    notifications?: [];
    topics?: [];
    broadcasts?: [];
    chatbotLogs?: [];
    featuredCountries?: [];
    fareFields?: [];
    moreLinks?: [];
    news?: [];
    introSlides?: [];
    flightNotifications?: {};
    geofences?: [];
    notificationViewCounts: {
        [key: string]: number;
    };

    constructor() {
        return {
            background_categories: {},
            destinations: [],
            kioskNames: [],
            categories: [],
            transport: [],
            icons: [],
            pois: [],
            concourses: [],
            users: [],
            hotels: [],
            laxpois: [],
            attractions: [],
            videos: [],
            idleState: [],
            category: [],
            pages: [],
            pagesOrdering: [],
            category_dropdown: [],
            emergency: {},
            emergencybroadcast: {},
            poiCategories: [],
            survey: [],
            survey_config: {},
            kioskname: [],
            distributionLists: [],
            specialUplift: [],
            flights: [],
            templates: [],
            airports: [],
            inventories: [],
            mapElementIDs: [],
            tags: [],
            airlines: {},
            parentPages: [],
            cities: [],
            config: {},
            home_banners: new HomeBannerModel(),
            banners: [],
            offers: [],
            equipment: [],
            fares: [],
            terms: {},
            importantReminders: {},
            notifications: [],
            topics: [],
            broadcasts: [],
            chatbotLogs: [],
            featuredCountries: [],
            fareFields: [],
            moreLinks: [],
            news: [],
            introSlides: [],
            flightNotifications: {},
            geofences: [],
            notificationViewCounts: {},
        };
    }
}
