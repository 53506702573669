import { Menu } from '@app/models/menu.model';

export const menuConfig: Menu[] = [
    {
        headerTitle: 'MENU.CONTENT',
        roles: [],
    },
    {
        title: 'MENU.ATTRACTIONS',
        path: '/dashboard/content/attractions',
        icon: 'icofont icofont-star',
        routeName: 'attractions',
        name: 'attractions',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.HOTELS',
        path: '/dashboard/content/hotels',
        icon: 'icofont icofont-hotel',
        routeName: 'hotels',
        name: 'hotels',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.PAGE_CATEGORIES',
        icon: 'icofont icofont-chart-flow-alt-2',
        path: '/dashboard/content/category',
        routeName: 'category',
        name: 'category',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.PAGES',
        icon: 'icofont icofont-file-document',
        path: '/dashboard/content/pages',
        routeName: 'pages',
        active: false,
        name: 'pages',
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.SURVEY_QUESTIONS',
        icon: 'icofont icofont-question-circle',
        path: '/dashboard/content/survey',
        routeName: 'survey',
        name: 'survey',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.VIDEOS',
        path: '/dashboard/content/videos',
        icon: 'icofont icofont-video',
        routeName: 'videos',
        name: 'videos',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.IDLE_STATE',
        path: '/dashboard/content/idleState',
        icon: 'icofont icofont-list',
        routeName: 'idleState',
        name: 'idleState',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        headerTitle: 'MENU.FEATURES',
        roles: [],
    },
    {
        title: 'MENU.EMERGENCY',
        path: '/dashboard/content/emergencybroadcast',
        icon: 'icofont icofont-warning',
        routeName: 'emergencybroadcast',
        name: 'emergencybroadcast',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.POI_OVERRIDE',
        path: '/dashboard/content/laxpois',
        icon: 'icofont icofont-bullseye',
        routeName: 'laxpois',
        name: 'laxpois',
        type: 'link',
        active: false,
        roles: [],
    },
    {
        headerTitle: 'MENU.CONFIG',
        roles: [],
    },
    {
        title: 'MENU.KIOSK_NAMES',
        active: true,
        path: '/dashboard/content/kioskname',
        icon: 'icofont icofont-imac',
        routeName: 'kioskname',
        name: 'kioskname',
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.AGENTS',
        path: '/dashboard/content/agents',
        icon: 'icofont icofont-headphone-alt-2',
        routeName: 'agents',
        roles: ['admin'],
        name: 'agents',
        type: 'link',
        active: true,
    },
    {
        title: 'MENU.USERS',
        path: '/dashboard/content/users',
        icon: 'icofont icofont-people',
        routeName: 'users',
        roles: ['admin'],
        name: 'users',
        type: 'link',
        active: true,
    },
];
