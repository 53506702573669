import { setLoginImage } from '../actions/ui.actions';
import { createReducer, on } from '@ngrx/store';
import { UIState } from '../state/ui.state';
import {
    getAllUsersFailed,
    getAllUsersSuccess,
    getFlightsSuccess,
    getFlightsFailed,
    getAllUsers,
    getFlights,
} from '../actions/dataBus.actions';

const initialState = {
    images: {
        loginImage: 1,
    },
    isLoadingUsers: true,
    isLoadingFlights: true,
} as UIState;

const uiReducer = createReducer(
    initialState,

    on(setLoginImage, (state, action) => {
        const _u = { ...state };
        _u.images.loginImage = action.loginImage;
        return _u;
    }),
    on(getAllUsers, (state) => {
        const _u = { ...state };
        _u.isLoadingUsers = true;
        return _u;
    }),
    on(getAllUsersSuccess, (state) => {
        const _u = { ...state };
        _u.isLoadingUsers = false;
        return _u;
    }),
    on(getAllUsersFailed, (state) => {
        const _u = { ...state };
        _u.isLoadingUsers = false;
        return _u;
    }),
    on(getFlights, (state) => {
        const _u = { ...state };
        _u.isLoadingFlights = true;
        return _u;
    }),
    on(getFlightsSuccess, (state) => {
        const _u = { ...state };
        _u.isLoadingFlights = false;
        return _u;
    }),
    on(getFlightsFailed, (state) => {
        const _u = { ...state };
        _u.isLoadingFlights = false;
        return _u;
    })
);

export default function UIReducer(state: any, action: any): any {
    return uiReducer(state, action);
}
