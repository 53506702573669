export class RealTimeDatabaseModel {
    wordpress: any;
    config: any;
    menuConfig: any;
    constructor(props?: RealTimeDatabaseModel) {
        return {
            wordpress: props?.wordpress ? props?.wordpress : {},
            config: props?.config ? props?.config : {},
            menuConfig: props?.menuConfig ? props?.menuConfig : null,
        };
    }
}
