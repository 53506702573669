import { GoogleAnalyticsService } from './services/google-analytics.service';
import { EventHubService } from './services/event-hub.service';
import { Router, NavigationStart } from '@angular/router';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Loader } from '@googlemaps/js-api-loader';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private translate: TranslateService,
        private eventHub: EventHubService,
        private router: Router
    ) {
        document.title = environment.pageName;
        translate.setDefaultLang('en');

        googleAnalyticService.createTracker();

        router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
                setTimeout(() => {
                    this.eventHub.sideMenuCollapsedEvent.emit(true);
                }, 500);
            }
        });
    }

    ngOnInit(): void {
        if (environment.googleMapsKey) {
            if (!window.document.getElementById('google-map-script')) {
                const loader = new Loader({
                    apiKey: environment.googleMapsKey,
                    version: 'weekly',
                    libraries: ['geometry', 'places'],
                });

                loader.importLibrary('maps');
            }
        }
    }
}
