import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class EventHubService {
    public triggerUploadImageError = new EventEmitter();
    public sideMenuCollapsedEvent = new EventEmitter();
    public updateExistingImageUrl = new EventEmitter();
    public triggerLoadingContent = new EventEmitter();
    public updateDynamicFormPath = new EventEmitter();
    public triggerSavingResult = new EventEmitter();
    public triggerImageLoaded = new EventEmitter();
    public triggerUploadImage = new EventEmitter();
    public cartsDataUpdated = new EventEmitter();
    public updateTableTabs = new EventEmitter();
    public refreshTable = new EventEmitter();

    constructor() {}
}
