import { DialogMessageModel } from '@app/models/dialogMessage.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dialog-message',
    templateUrl: './dialog-message.component.html',
    styleUrls: ['./dialog-message.component.scss'],
})
export class DialogMessageComponent implements OnInit {
    @Input() dialogMessage: DialogMessageModel = new DialogMessageModel({});

    cancelRequestButton = false;
    declineButton = false;
    isReasonValid = false;
    resetPassword = false;
    isValidEmail = false;
    inputTouched = false;
    reasonText = '';
    email = '';
    path = '';

    constructor(public activeModal: NgbActiveModal, private router: Router) {}

    ngOnInit(): void {
        const routerSplit = this.router.routerState.snapshot.url.split('/');
        this.path = routerSplit[routerSplit.length - 2];

        this.cancelRequestButton = this.dialogMessage.viewButtons.find(
            (element) => element === 'cancelRequest'
        )
            ? true
            : false;

        this.declineButton = this.dialogMessage.viewButtons.find(
            (element) => element === 'decline'
        )
            ? true
            : false;

        this.resetPassword = this.dialogMessage.viewButtons.find(
            (element) => element === 'reset'
        )
            ? true
            : false;
    }

    checkReason(input: any): void {
        const val = input.target.value ? input.target.value.trimLeft() : '';

        this.reasonText = val;

        if (val && val.trim().length > 5) {
            this.isReasonValid = true;
        } else {
            this.isReasonValid = false;
        }
    }

    onCheckEmail(email: any): void {
        this.inputTouched = true;
        this.email = email.target.value;

        if (email.target.value) {
            const emailAddress = new RegExp(
                `^(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\\-+)|([A-Za-z0-9]+\\.+)|([A-Za-z0-9]+\\++))*[A-Za-z0-9]+@((\\w+\\-+)|(\\w+\\.))*\\w{1,63}\\.[a-zA-Z]{2,6}$`
            );

            if (emailAddress.test(email.target.value)) {
                this.isValidEmail = true;
            } else {
                this.isValidEmail = false;
            }
        }
    }

    onPassBack(btnText: string): void {
        if (btnText === 'decline' || btnText === 'cancelRequest') {
            this.activeModal.close({
                type: btnText,
                reason: this.reasonText,
            });
        } else if (btnText === 'reset') {
            this.activeModal.close({
                type: btnText,
                email: this.email,
            });
        } else {
            this.activeModal.close(btnText);
        }
    }
}
