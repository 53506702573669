import { environment } from 'environments/environment';

export class UserModel {
    role?: string;
    languages?: Array<string>;
    firstName?: string;
    lastName?: string;
    email?: string;
    providerIDs?: Array<string>;

    constructor(props: UserModel) {
        const obj = {
            role: props.role ? props.role : '',
            languages:
                environment.appFlavor === 'lga' ||
                environment.appFlavor === 'mea'
                    ? props.languages
                        ? props.languages || ['en']
                        : ['en']
                    : props.languages
                    ? props.languages || ['en', 'es']
                    : ['en', 'es'],
            firstName: props.firstName ? props.firstName : '',
            lastName: props.lastName ? props.lastName : '',
            email: props.email ? props.email : '',
            providerIDs: props.providerIDs || [],
        };

        if (
            environment.appFlavor === 'mia' ||
            environment.appFlavor === 'elpaso'
        ) {
            delete obj.languages;
        }

        return obj;
    }
}

export class UpdateUserModel {
    uid?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: string;
    languages?: Array<string>;
    disabled?: boolean;
    providerIDs?: Array<string>;

    constructor(props: UpdateUserModel) {
        const obj = {
            uid: props.uid ? props.uid : '',
            firstName: props.firstName ? props.firstName : '',
            lastName: props.lastName ? props.lastName : '',
            email: props.email ? props.email : '',
            role: props.role ? props.role : '',
            languages:
                environment.appFlavor === 'lga' ||
                environment.appFlavor === 'mea'
                    ? props.languages
                        ? props.languages || ['en']
                        : ['en']
                    : props.languages
                    ? props.languages || ['en', 'es']
                    : ['en', 'es'],
            disabled: props.disabled ? props.disabled : false,
            providerIDs: props.providerIDs || [],
        };

        if (
            environment.appFlavor === 'mia' ||
            environment.appFlavor === 'elpaso'
        ) {
            delete obj.languages;
        }

        return obj;
    }
}

export class DeleteUserModel {
    userId?: string;

    constructor(props: { userId?: string }) {
        return {
            userId: props.userId || '',
        };
    }
}
