export class ColumnLayout {
    text?: string | number;
    style?: string;

    constructor(props?: ColumnLayout) {
        return {
            text: props.text || 0,
            style: props.style || 'code',
        };
    }
}

export class LanguageTabsPropsModel {
    label?: string;
    key?: string;
    title?: string;
    errorMessages?: {
        required?: boolean;
        minLength?: number;
        maxLength?: number;
    };
    id?: string;
}
