import { EventHubService } from '@app/services/event-hub.service';
import { MappingService } from '@app/services/mapping.service';
import { UtilityService } from '@app/services/utility.service';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Survey } from '@app/models/services/mappingModels';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { AuthService } from '@app/services/auth.service';
import { ApiService } from '@app/services/api-service';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { types } from '../actions';
import { of } from 'rxjs';

@Injectable()
export class UIEffects {
    getContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_CONTENT_ATTEMPT),
            mergeMap(() => {
                this.utility.isLoadingContent = true;
                this.eventHub.triggerLoadingContent.emit(true);

                return this.api.getApiCall('contents/getContents', true).pipe(
                    map((response) => {
                        const content = response.body;
                        this.api.isGetContentSuccess = true;
                        this.eventHub.sideMenuCollapsedEvent.emit(true);
                        let specialUpliftValue = [];

                        if (
                            content.data &&
                            content.data.forms &&
                            content.data.forms.specialUplift &&
                            content.data.forms.specialUplift.config
                        ) {
                            specialUpliftValue =
                                content.data.forms.specialUplift.config.options;
                        }
                        //TODO Maybe handle this in the model :hg

                        setTimeout(() => {
                            this.eventHub.triggerSavingResult.emit(true);
                        }, 200);

                        const mapPages = this.mappingService.mapPages(
                            content.data.pages || [],
                            content.data.pageOrder
                        );

                        const mapVideos = this.mappingService.mapVideos(
                            content.data.videos || []
                        );

                        this.utility.isLoadingContent = false;
                        this.eventHub.triggerLoadingContent.emit(false);

                        return {
                            type: types.GET_CONTENT_SUCCESS,
                            distributionLists:
                                this.mappingService.mapDistributionList(
                                    content.data.forms || []
                                ),
                            hotels: this.mappingService.mapHotelsAndAttractions(
                                content.data.hotels || [],
                                'hotels'
                            ),
                            attractions:
                                this.mappingService.mapHotelsAndAttractions(
                                    content.data.attractions || [],
                                    'attractions'
                                ),
                            videos: mapVideos,
                            idleState: this.mappingService.mapIdleState(
                                content.data.idleState,
                                mapVideos
                            ),
                            category: this.mappingService.mapCategory(
                                content.data.pages || []
                            ),
                            pages: mapPages?.pages ? mapPages.pages : mapPages,
                            pagesOrdering: content.data.pagesOrdering,
                            category_dropdown:
                                this.mappingService.mapCategoryDropDown(
                                    content.data.pages || []
                                ),
                            emergencybroadcast:
                                content.data.emergencyBroadcast || {},
                            survey: this.mappingService.mapSurvey(
                                content.data.surveyConfig || []
                            ),
                            survey_config:
                                content.data.surveyConfig &&
                                typeof content.data.surveyConfig === 'object' &&
                                Object.keys(content.data.surveyConfig)?.length >
                                    0
                                    ? new Survey(content.data.surveyConfig)
                                    : new Survey({}),
                            background_categories:
                                content.data.background_categories || [],
                            kioskNames: this.mappingService.mapKioskNames(
                                content.data.kiosk_names ||
                                    content.data.kioskNames ||
                                    []
                            ),
                            kioskname: this.mappingService.mapKioskNamesLAX(
                                content.data.kioskNames || []
                            ),
                            categories: this.mappingService.mapCategories(
                                content.data.map_categories ||
                                    content.data.categories ||
                                    []
                            ),
                            destinations: content.data.destinations || [],
                            transport: content.data.transport || [],
                            mapElementIDs: content.data.mapElementIDs || [],
                            icons: content.data.icons || [],
                            pois: this.mappingService.mapPois(
                                content.data.pois || []
                            ),
                            concourses: this.mappingService.mapConcourses(
                                content.data.gate_headers || []
                            ),
                            specialUplift: specialUpliftValue || [],
                            tags: this.mappingService.mapTags(
                                content.data.tags || []
                            ),
                            poiCategories: this.mappingService.mapPoiCategories(
                                content.data.poiCategories || [],
                                'poiCategories'
                            ),
                            airlines: this.mappingService.mapMiaAirlines(
                                content.data.airlines || {}
                            ),
                            parentPages: mapPages.parentPages || [],
                            cities: content.data.cities || [],
                            config: this.mappingService.mapMIAConfig(
                                content.data.config || {}
                            ),
                            home_banners:
                                environment.appFlavor !== 'bwa' &&
                                environment.appFlavor !== 'mvp' &&
                                environment.appFlavor !== 'afw' &&
                                environment.appFlavor !== 'universal'
                                    ? this.mappingService.mapHomeBanners()
                                    : {},
                            emergency: content.data.emergency || {},
                            banners: this.mappingService.mapAirlineAppBanners(
                                content.data.banners || []
                            ),
                            airports: this.mappingService.mapAirlineAppAirports(
                                content.data.airports || {}
                            ),
                            offers: this.mappingService.mapAirlineAppOffers(
                                content.data.offers || []
                            ),
                            lastModified: response.headers.get('last-modified'),
                            equipment:
                                this.mappingService.mapAirlineAppEquipment(
                                    content.data.equipment || {}
                                ),
                            fares: this.mappingService.mapAirlineAppFares(
                                content.data.fares || {}
                            ),
                            terms: content.data.terms || {},
                            importantReminders:
                                content.data.importantReminders || {},
                            chatbotLogs: this.mappingService.mapChatbotLogs(
                                content.data.chatbotLogs || {}
                            ),
                            fareFields:
                                this.mappingService.mapFareFieldsAirlineApp(
                                    content.data.fareFields || {}
                                ),
                            moreLinks: content.data.moreLinks || [],
                            introSlides:
                                this.mappingService.mapIntroSlidesAirlineApp(
                                    content.data.introSlides || []
                                ),
                            news: content.data.news || [],
                            featuredCountries:
                                content.data.featuredCountries || [],
                            flightNotifications:
                                this.mappingService.mapFlightNotifications(
                                    content.flightSubscriptions || {}
                                ),
                            geofences: this.mappingService.mapGeoFences(
                                content.data.geofences || []
                            ),
                            notificationViewCounts:
                                content.data.notificationViewCounts,
                        };
                    }),
                    catchError((error) => {
                        this.utility.isLoadingContent = false;
                        this.eventHub.triggerLoadingContent.emit(false);

                        return of({
                            type: types.GET_CONTENT_FAILED,
                            isLastModified: error.status === 304,
                        });
                    })
                );
            })
        )
    );

    getContentByNode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_CONTENT_BY_NODE_ATTEMPT),
            mergeMap(() => {
                this.utility.isLoadingContent = true;
                this.eventHub.triggerLoadingContent.emit(true);

                return this.api.getApiCall('contents/getContents', false).pipe(
                    map((response) => {
                        const content = response.body;
                        this.api.isGetContentSuccess = true;
                        this.eventHub.sideMenuCollapsedEvent.emit(true);

                        setTimeout(() => {
                            this.eventHub.triggerSavingResult.emit(true);
                        }, 200);

                        let specialUpliftValue = [];

                        if (
                            content.data &&
                            content.data.forms &&
                            content.data.forms.specialUplift &&
                            content.data.forms.specialUplift.config
                        ) {
                            specialUpliftValue =
                                content.data.forms.specialUplift.config.options;
                        }

                        const pages = this.mappingService.mapPages(
                            content.data.pages || [],
                            content.data.pageOrder
                        );
                        const mapPages =
                            this.utility.currentSelectedNode === 'pages'
                                ? pages
                                : this.utility.databusContent.pages;

                        this.utility.isLoadingContent = false;
                        this.eventHub.triggerLoadingContent.emit(false);

                        return {
                            type: types.GET_CONTENT_SUCCESS,
                            categories:
                                this.utility.currentSelectedNode ===
                                'categories'
                                    ? this.mappingService.mapCategories(
                                          content.data.map_categories ||
                                              content.data.categories ||
                                              []
                                      )
                                    : this.utility.databusContent.categories ||
                                      [],
                            pois:
                                this.utility.currentSelectedNode === 'pois'
                                    ? this.mappingService.mapPois(
                                          content.data.pois || []
                                      )
                                    : this.utility.databusContent.pois || [],
                            concourses: this.mappingService.mapConcourses(
                                content.data.gate_headers || []
                            ),
                            hotels:
                                this.utility.currentSelectedNode === 'hotels'
                                    ? this.mappingService.mapHotelsAndAttractions(
                                          content.data.hotels || [],
                                          'hotels'
                                      )
                                    : this.utility.databusContent.hotels || [],
                            pages: mapPages?.pages ? mapPages.pages : mapPages,
                            pagesOrdering: content.data.pagesOrdering,
                            emergency: content.data.emergency || {},
                            poiCategories:
                                this.utility.currentSelectedNode ===
                                'poiCategories'
                                    ? this.mappingService.mapPoiCategories(
                                          content.data.poiCategories || [],
                                          'poiCategories'
                                      )
                                    : this.utility.databusContent
                                          .poiCategories || [],
                            distributionLists:
                                this.utility.currentSelectedNode ===
                                'distributionLists'
                                    ? this.mappingService.mapDistributionList(
                                          content.data.forms || []
                                      )
                                    : this.utility.databusContent
                                          .distributionLists || [],
                            tags:
                                this.utility.currentSelectedNode === 'tags'
                                    ? this.mappingService.mapTags(
                                          content.data.tags || []
                                      )
                                    : this.utility.databusContent.tags || [],
                            airlines:
                                this.utility.currentSelectedNode === 'airlines'
                                    ? this.mappingService.mapMiaAirlines(
                                          content.data.airlines || {}
                                      )
                                    : this.utility.databusContent.airlines ||
                                      {},
                            parentPages: pages.parentPages || [],
                            cities: content.data.cities || [],
                            config:
                                this.utility.currentSelectedNode === 'config'
                                    ? this.mappingService.mapMIAConfig(
                                          content.data.config || {}
                                      )
                                    : this.utility.databusContent.config || {},
                            home_banners:
                                this.utility.currentSelectedNode ===
                                'home_banners'
                                    ? environment.appFlavor !== 'bwa' &&
                                      environment.appFlavor !== 'mvp' &&
                                      environment.appFlavor !== 'afw' &&
                                      environment.appFlavor !== 'universal'
                                        ? this.mappingService.mapHomeBanners()
                                        : {}
                                    : this.utility.databusContent
                                          .home_banners || {},
                            banners:
                                this.utility.currentSelectedNode === 'banners'
                                    ? this.mappingService.mapAirlineAppBanners(
                                          content.data.banners || []
                                      )
                                    : this.utility.databusContent.banners || [],
                            airports:
                                this.utility.currentSelectedNode === 'airports'
                                    ? this.mappingService.mapAirlineAppAirports(
                                          content.data.airports || {}
                                      )
                                    : this.utility.databusContent.airports ||
                                      [],
                            offers:
                                this.utility.currentSelectedNode === 'offers'
                                    ? this.mappingService.mapAirlineAppOffers(
                                          content.data.offers || []
                                      )
                                    : this.utility.databusContent.offers || [],
                            destinations: content.data.destinations || [],
                            transport: content.data.transport || [],
                            mapElementIDs: content.data.mapElementIDs || [],
                            icons: content.data.icons || [],
                            specialUplift: specialUpliftValue || [],
                            attractions:
                                this.utility.currentSelectedNode ===
                                'attractions'
                                    ? this.mappingService.mapHotelsAndAttractions(
                                          content.data.attractions || [],
                                          'attractions'
                                      )
                                    : this.utility.databusContent.attractions ||
                                      [],
                            videos:
                                this.utility.currentSelectedNode === 'videos'
                                    ? this.mappingService.mapVideos(
                                          content.data.videos || []
                                      )
                                    : this.utility.databusContent.videos || [],
                            category:
                                this.mappingService.mapCategory(
                                    content.data.pages || []
                                ) || [],
                            category_dropdown:
                                this.mappingService.mapCategoryDropDown(
                                    content.data.pages || []
                                ) || [],
                            emergencybroadcast:
                                content.data.emergencyBroadcast || {},
                            survey:
                                this.mappingService.mapSurvey(
                                    content.data.surveyConfig || []
                                ) || [],
                            survey_config:
                                content.data.surveyConfig &&
                                typeof content.data.surveyConfig === 'object' &&
                                Object.keys(content.data.surveyConfig)?.length >
                                    0
                                    ? new Survey(content.data.surveyConfig)
                                    : new Survey({}),
                            background_categories:
                                content.data.background_categories || [],
                            kioskNames:
                                this.mappingService.mapKioskNames(
                                    content.data.kiosk_names ||
                                        content.data.kioskNames ||
                                        []
                                ) || [],
                            kioskname:
                                this.mappingService.mapKioskNamesLAX(
                                    content.data.kioskNames || []
                                ) || [],
                            fares: this.mappingService.mapAirlineAppFares(
                                content.data.fares || {}
                            ),
                            equipment:
                                this.mappingService.mapAirlineAppEquipment(
                                    content.data.equipment || {}
                                ),
                            terms: content.data.terms || {},
                            importantReminders:
                                content.data.importantReminders || {},
                            chatbotLogs: this.mappingService.mapChatbotLogs(
                                content.data.chatbotLogs || {}
                            ),
                            fareFields:
                                this.mappingService.mapFareFieldsAirlineApp(
                                    content.data.fareFields || {}
                                ),
                            moreLinks:
                                this.utility.currentSelectedNode?.toLowerCase() ===
                                'morelinks'
                                    ? content.data.moreLinks || []
                                    : this.utility.databusContent.moreLinks ||
                                      [],
                            introSlides:
                                this.utility.currentSelectedNode?.toLowerCase() ===
                                'introslides'
                                    ? this.mappingService.mapIntroSlidesAirlineApp(
                                          content.data.introSlides || []
                                      )
                                    : this.utility.databusContent.introSlides ||
                                      [],
                            news: content.data.news || [],
                            featuredCountries:
                                content.data.featuredCountries || [],
                            flightNotifications:
                                this.mappingService.mapFlightNotifications(
                                    content.flightSubscriptions || {}
                                ),
                            geofences:
                                this.utility.currentSelectedNode?.toLowerCase() ===
                                'geofences'
                                    ? this.mappingService.mapGeoFences(
                                          content.data.geofences || []
                                      )
                                    : this.utility.databusContent.geofences ||
                                      [],
                        };
                    }),
                    catchError(() => {
                        this.utility.isLoadingContent = false;
                        this.eventHub.triggerLoadingContent.emit(false);

                        return of({
                            type: types.GET_CONTENT_FAILED,
                        });
                    })
                );
            })
        )
    );

    getAllUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_ALL_USERS_ATTEMPT),
            mergeMap(() => {
                this.authService.isLoadingUsers = true;
                return this.api.getApiCall('users/getAllUsers').pipe(
                    map((response) => {
                        const content = response.body;
                        this.authService.loginLoading = false;

                        setTimeout(() => {
                            let route = 'welcome';
                            this.utility.menuItems.forEach((item) => {
                                if (
                                    item.routeName ===
                                        this.utility.lastVisitedPage &&
                                    (item.roles.length === 0 ||
                                        item.roles.includes(
                                            this.utility.userRole
                                        ))
                                ) {
                                    route = item.routeName;
                                }
                            });
                            this.authService.isLoadingUsers = false;

                            this.router.navigate([
                                `/dashboard/content/${route}`,
                            ]);
                        }, 10);

                        return {
                            type: types.GET_ALL_USERS_SUCCESS,
                            users: this.mappingService.mappingUsers(
                                content.data
                            ),
                        };
                    }),
                    catchError(() => {
                        this.authService.loginLoading = false;
                        this.authService.isLoadingUsers = false;

                        return of({
                            type: types.GET_ALL_USERS_FAILED,
                        });
                    })
                );
            })
        )
    );

    //MEA

    getResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_REQUEST_ATTEMPT),
            mergeMap((action) =>
                this.api.postApiCall('getRequests', action['status']).pipe(
                    map((response) => {
                        return {
                            type: types.GET_REQUEST_SUCCESS,
                            node: action['node'],
                            apps: this.mappingService.formatRequests(
                                response.data
                            ),
                        };
                    }),
                    catchError(() =>
                        of({
                            type: types.GET_REQUEST_FAILED,
                        })
                    )
                )
            )
        )
    );

    getFlights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_FLIGHTS_ATTEMPT),
            mergeMap(() =>
                this.api
                    .postApiCall('flightList', {
                        departureAirportCode: 'BEY',
                        daysBefore: 0,
                        daysAfter: 30,
                        getAssociatedFlights: true,
                        operatingAirline: 'ME',
                    })
                    .pipe(
                        map((content) => {
                            return {
                                type: types.GET_FLIGHTS_SUCCESS,
                                flights: this.mappingService.mapFlightsList(
                                    content.data || []
                                ),
                                airports: this.mappingService.mapAirports(
                                    content.data
                                ),
                            };
                        }),
                        catchError(() =>
                            of({
                                type: types.GET_FLIGHTS_FAILED,
                            })
                        )
                    )
            )
        )
    );

    getTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_TEMPLATES_ATTEMPT),
            mergeMap(() =>
                this.api.postApiCall('getTemplates', {}).pipe(
                    map((content) => {
                        this.api.isGetContentSuccess = true;
                        return {
                            type: types.GET_TEMPLATES_SUCCESS,
                            templates: this.mappingService.mapTemplates(
                                content.data || []
                            ),
                        };
                    }),
                    catchError(() =>
                        of({
                            type: types.GET_TEMPLATES_FAILED,
                        })
                    )
                )
            )
        )
    );

    getFlightInventories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_FLIGHT_INVENTORIES_ATTEMPT),
            mergeMap(() =>
                this.api.getApiCall('GetFlightInventories').pipe(
                    map((response) => {
                        const content = response.body;
                        return {
                            type: types.GET_FLIGHT_INVENTORIES_SUCCESS,
                            inventories: this.mappingService.mapInventories(
                                content.data
                            ),
                            inventoriesLastHours: content.hours
                                ? content.hours
                                : '',
                            inventoriesLastDays: content.days
                                ? content.days
                                : '',
                        };
                    }),
                    catchError(() =>
                        of({
                            type: types.GET_FLIGHT_INVENTORIES_FAILED,
                        })
                    )
                )
            )
        )
    );

    constructor(
        private mappingService: MappingService,
        private eventHub: EventHubService,
        private authService: AuthService,
        private utility: UtilityService,
        private actions$: Actions,
        private api: ApiService,
        private router: Router
    ) {}
}
