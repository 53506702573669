export const types = {
    // DataBus

    SET_DATA: '[DataBus] Set Data',
    GET_CONTENT_SUCCESS: '[DataBus] GET Content Success',
    GET_CONTENT_ATTEMPT: '[DataBus] GET Content Attempt',
    GET_CONTENT_FAILED: '[DataBus] GET Content Failed',

    GET_CONTENT_BY_NODE_ATTEMPT: '[DataBus] GET Content By Node Attempt',

    SET_SELECTED_INVENTORY_Template:
        '[DataBus] SET Selected Inventory Template',
    SET_AGENTS: '[DataBus] SET Agents',
    SET_CARTS: '[DataBus] SET Carts',

    GET_TEMPLATES_SUCCESS: '[DataBus] GET Templates Success',
    GET_TEMPLATES_ATTEMPT: '[DataBus] GET Templates Attempt',
    GET_TEMPLATES_FAILED: '[DataBus] GET  Templates Failed',

    SET_RTDB_NODE: '[RTDB] Set RTDB Node',

    // UI
    SET_ROW_DATA: '[UI] Setting the Row Data in the store',
    SELECT_ROW_DATA: '[UI] Setting the Row Data in the selectedRow',
    UPDATE_NODE_DATA: '[UI] Update  NODE Data in the state',
    SET_CATEGORY_BG_IMAGE: '[UI] Set  Category background-image in the state',
    SET_FILTER_VALUE: '[UI] Set  Filter Value in the state',

    GET_ALL_USERS_SUCCESS: '[UI] GET All Users Success',
    GET_ALL_USERS_ATTEMPT: '[UI] GET All Users Attempt',
    GET_ALL_USERS_FAILED: '[UI] GET  All Users Failed',

    GET_REQUEST_SUCCESS: '[UI] GET Request Success',
    GET_REQUEST_ATTEMPT: '[UI] GET Request Attempt',
    GET_REQUEST_FAILED: '[UI] GET  Request Failed',

    GET_FLIGHTS_SUCCESS: '[UI] GET Flights Success',
    GET_FLIGHTS_ATTEMPT: '[UI] GET Flights Attempt',
    GET_FLIGHTS_FAILED: '[UI] GET  Flights Failed',

    GET_FLIGHT_INVENTORIES_SUCCESS: '[UI] GET Flight inventories Success',
    GET_FLIGHT_INVENTORIES_ATTEMPT: '[UI] GET Flight inventories Attempt',
    GET_FLIGHT_INVENTORIES_FAILED: '[UI] GET  Flight inventories Failed',

    // Users
    SET_USER_TOKEN: 'setting the user token in the state',
    RESET_USER_TOKEN: 'Reset the user token after log out',
    SET_CURRENT_TIME: 'set current time in the state',
    SET_NODE: '[UI] Setting the Node',
    SET_USER_TOKEN_AND_LOGIN_TIME:
        'setting the user token and the loginTime in the state',
    SET_USER_LAST_VISITED_PAGE: 'Set the user last visited page',

    // Images
    SET_LOGIN_IMAGE: '[UI] Setting Login Image',
};
